import {
  Alert,
  AlertDescription,
  AlertIcon,
  Avatar,
  Box,
  Button,
  CloseButton,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Heading,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';

import { Link as ReactLink } from 'react-router-dom';

export default function NavLink({ children, link, onClose, ...style }) {
  return (
    <Link
      as={ReactLink}
      px={[4, 2, 2, 4]}
      py={2}
      h="100%"
      fontSize={['md', '0.9em', '0.8em', '0.9em', 'md']}
      rounded={'md'}
      _hover={{
        textDecoration: 'none',
        bg: 'gray.100',
      }}
      to={link}
      display="flex"
      alignItems={'center'}
      onClick={() => {
        setTimeout(onClose, 200);
      }}
      {...style}
    >
      {children}
    </Link>
  );
}
