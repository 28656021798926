import { getFlickrPhotoIds } from '../brain/api';

const SIZE_MAP = {
  '3xs': 's', // 75 square
  '2xs': 't', // 100
  xs: 'q', // 150 square
  sm: 'm', // 240
  // md: 'n', // 320
  md: 'w', // 400
  // xl: '', // 500
  lg: 'z', // 640
  // '3xl': 'c', // 800
  xl: 'b', // 1024
};

// Flick size: https://www.flickr.com/services/api/misc.urls.html
export async function getPhotos({ text, size = 'lg', limit = 10 }) {
  const {
    photos: { photo },
  } = await getFlickrPhotoIds({
    text,
    limit,
  });
  const flickrSize = SIZE_MAP[size];
  const links = photo.map(
    (p) =>
      `https://live.staticflickr.com/${p.server}/${p.id}_${p.secret}_${flickrSize}.jpg`
  );
  if (size === 1 && links?.length > 0) {
    return links[0];
  }
  return links;
}
