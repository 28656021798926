import { Divider, Flex } from '@chakra-ui/react';

import SuggestRow from './SuggestRow.jsx';

export default function SuggestSection(props) {
  const { suggestion } = props;
  const { children, name: parentName, entity_type: parentType } = suggestion;
  return (
    <Flex
      direction="column"
      align="stretch"
      p={1.5}
      _hover={{
        cursor: 'default',
      }}
    >
      <SuggestRow name={parentName} type={parentType} outing={suggestion} />
      {!!children && (
        <Flex align="stretch" direction="column">
          {children.map((child) => {
            if (
              child.entity_type === 'ticketfacility' ||
              child.entity_type === 'timedentry' ||
              child.entity_type === 'park-lodge' ||
              child.entity_type === 'tracks'
            ) {
              return null;
            }
            return (
              <SuggestRow
                key={child._id}
                name={child.name}
                isChild
                type={child.entity_type}
                outing={child}
              />
            );
          })}
        </Flex>
      )}
    </Flex>
  );
}
