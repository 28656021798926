import {
  Box,
  Button,
  Card,
  Flex,
  Image,
  Link,
  Slide,
  Stack,
  Text,
  chakra,
  keyframes,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { PLAN_PROPERTIES_MAP, COUNTRY_CURRENCY_MAP } from '#constants';
import PricingFeature from './PricingFeature.jsx';
import { Link as ReactLink } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { useUIStore } from '#store';

export default function PricingPlan({
  frequency,
  offer,
  onClick,
  monthly,
  annual,
}) {
  const { location } = useUIStore(
    (state) => ({
      location: state.location,
    }),
    shallow
  );
  const slideUp = keyframes`
    20%, 100% {
      transform: translate3d(0,-90%,-10px);
    }
  `;

  const plan = frequency === 'MONTHLY' ? monthly : annual;
  const { name, cadence, slug } = plan;
  const planProperties = PLAN_PROPERTIES_MAP[slug];
  const { features, priority, buttonText } = planProperties;
  const freqText =
    cadence === 'MONTHLY' ? 'month' : cadence === 'ANNUAL' ? 'year' : '';
  // Get the currency of the user
  const currency = COUNTRY_CURRENCY_MAP[location.country];
  // Get cost according to currency
  const cost =
    plan.cost.find((c) => c.currency === currency)?.amount ??
    plan.cost[0].amount;

  return (
    <Box
      rounded={['none', 'lg']}
      shadow={['none', 'md']}
      bg="white"
      position="relative"
      sx={{ transformStyle: 'preserve-3d' }}
    >
      {offer && (
        <Link
          as={ReactLink}
          position={'absolute'}
          w="100%"
          transform="translateZ(-10px)"
          animation={`${slideUp} 5s linear 0s infinite forwards`}
          to={'/offers/money-back'}
          isExternal
        >
          <Text
            bg={'secondary.600'}
            py={[4]}
            borderTopRadius="lg"
            color="white"
            fontSize={['md', 'lg']}
            textAlign={'center'}
            _hover={{
              textDecoration: 'underline',
            }}
          >
            No availability, 100% credit refund.
          </Text>
        </Link>
      )}
      {cadence === 'MONTHLY' && slug !== 'pay-as-you-go' && (
        <Stack
          position={'absolute'}
          top={['-50px', '-52px']}
          transform="translateZ(-10px)"
          w="100%"
        >
          <Text
            bg={'secondary.600'}
            py={[4]}
            borderTopRadius="lg"
            color="white"
            fontSize={['md', 'lg']}
            textAlign={'center'}
          >
            Switch to yearly plan to save 55% !
          </Text>
        </Stack>
      )}
      <Flex
        direction="column"
        justify="space-between"
        p="6"
        borderBottomWidth="1px"
        color="gray.200"
        align={'center'}
      >
        <chakra.p
          mb={1}
          fontSize={['md', 'lg']}
          fontWeight="semibold"
          color="gray.700"
        >
          {name}
        </chakra.p>
        <Text
          mb={2}
          fontSize={['4xl', '5xl']}
          fontWeight={['bold', 'extrabold']}
          color="gray.900"
          _dark={{
            color: 'gray.50',
          }}
          lineHeight="tight"
        >
          ${cost}
          {freqText && (
            <chakra.span
              fontSize={['xl', '2xl']}
              fontWeight="medium"
              color="gray.600"
              _dark={{
                color: 'gray.400',
              }}
            >
              {' '}
              / {freqText}
            </chakra.span>
          )}
        </Text>
        <Button
          w={['full', , '100%']}
          onClick={() => onClick(plan.slug)}
          display="inline-flex"
          alignItems="center"
          justifyContent="center"
          px={5}
          py={5}
          border="solid transparent"
          fontWeight="bold"
          rounded="md"
          shadow="md"
          color={'white'}
          bg={priority === 'primary' ? 'brand.500' : 'secondary.500'}
          _hover={{
            bg: priority === 'primary' ? 'brand.600' : 'secondary.300',
          }}
        >
          {buttonText}
        </Button>
      </Flex>
      <Stack direction="column" p="6" spacing={[1, '10px']} flexGrow="1">
        {features.map((feat, idx) => (
          <PricingFeature feat={feat} key={idx} />
        ))}
      </Stack>
    </Box>
  );
}
