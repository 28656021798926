import React, { useEffect, useState } from 'react';
import { AddressElement } from '@stripe/react-stripe-js';
import {
  FormControl,
  Button,
  Flex,
  HStack,
  Heading,
  Icon,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useUIStore } from '#store';

const AddressForm = () => {
  const setAddress = useUIStore((state) => state.setAddress);
  return (
    <FormControl>
      <Heading as="h3" mb={4} fontWeight={500} fontSize={['2xl', '3xl']}>
        Billing Details:
      </Heading>
      <AddressElement
        options={{ mode: 'billing' }}
        onChange={(event) => {
          if (event.complete) {
            // Extract potentially complete address
            const address = event.value.address;
            const name = event.value.name;
            const nameParts = name.split(' ');

            const firstName = nameParts[0];
            const lastName = nameParts.slice(1).join(' ');
            // This is the place to set billing address
            setAddress({
              firstName,
              lastName,
              line1: address.line1,
              line2: address.line2,
              state: address.state,
              country: address.country,
              city: address.city,
              zipcode: address.postal_code,
            });
          }
        }}
      />
    </FormControl>
  );
};

export default AddressForm;
