import { HStack, Heading, Image, Show } from '@chakra-ui/react';

import { LOGO_URL_DARK } from '#constants';
import { Link } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { useUIStore } from '#store';

export default function Logo() {
  const user = useUIStore((state) => state.user, shallow);
  return (
    <Link to="/" _hover={{ textDecoration: 'none' }}>
      <HStack>
        <Image
          src={LOGO_URL_DARK}
          alt="Reserve Nature"
          boxSize={['60px', '70px']}
        />
        <Show above="sm">
          <Heading
            size={['md', 'md', 'md', 'xl']}
            color="black"
            cursor="pointer"
            display={'flex'}
            alignItems="center"
            fontFamily="sans-serif"
          >
            Reserve Nature
          </Heading>
        </Show>
        <Show below="sm">
          {user && (
            <Heading
              size={['md', 'xl']}
              color="black"
              cursor="pointer"
              display={'flex'}
              alignItems="center"
              fontFamily="sans-serif"
            >
              Reserve Nature
            </Heading>
          )}
        </Show>
      </HStack>
    </Link>
  );
}
