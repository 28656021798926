import {
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  Icon,
  Image,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  Stack,
  Tag,
  TagLabel,
  Text,
} from '@chakra-ui/react';
import { GiCampfire, GiCampingTent } from 'react-icons/gi';
import { IoCheckmarkCircleSharp, IoCloseCircleSharp } from 'react-icons/io5';
import { TYPES_MAP, TYPE_ENABLED, DISCOUNT_CODE } from '#constants';
import { useEffect, useState } from 'react';

import { CalendarIcon } from '@chakra-ui/icons';
import { FaWheelchair } from 'react-icons/fa';
import { MdPeople } from 'react-icons/md';
import TrackerNotification from './TrackerNotification.jsx';
import { convertDateToUTC } from '#utils';
import { shallow } from 'zustand/shallow';
import { useUIStore } from '#store';

function SelectedCampsites() {
  const {
    outing,
    trackerInput: {
      filters: { campsites },
    },
    campsites: allCampsites,
  } = useUIStore(
    (state) => ({
      outing: state.outing,
      trackerInput: state.trackerInput,
      campsites: state.campsites,
    }),
    shallow
  );
  const canSelectChild =
    TYPE_ENABLED.specific.indexOf(outing?.entity_type) > -1;

  const campIdToNameMap = {};
  allCampsites.forEach((camp) => (campIdToNameMap[camp._id] = camp));

  if (!canSelectChild) {
    return <Flex></Flex>;
  }
  if (
    // showUpgrade ||
    canSelectChild &&
    !campsites?.length
  ) {
    return (
      <Flex>
        <Icon
          as={GiCampfire}
          color="brand.500"
          boxSize={5}
          mr={2}
          align="center"
        ></Icon>
        <Text>{`All ${TYPES_MAP[outing?.entity_type]}`}</Text>
      </Flex>
    );
  }
  return (
    <Flex overflowX={'scroll'} position="relative" align={'center'}>
      <Flex bg="white" position={'sticky'} py={1} align="center" left={0}>
        <Icon as={GiCampfire} color="brand.500" boxSize={5} mr={2}></Icon>
      </Flex>
      <Flex position={'relative'}>
        {campsites.map((camp_id) => (
          <Tag colorScheme={'brand'} mr={2} key={camp_id}>
            {campIdToNameMap[camp_id]?.name}
          </Tag>
        ))}
      </Flex>
    </Flex>
  );
}

function CampsiteSummary() {
  const {
    outing,
    searchInput,
    trackerInput: {
      groupSize,
      equipmentType,
      startDate: checkin,
      endDate: checkout,
      filters: { campsites, accessible },
    },
    planSlug,
  } = useUIStore(
    (state) => ({
      outing: state.outing,
      searchInput: state.searchInput,
      trackerInput: state.trackerInput,
      planSlug: state.planSlug,
    }),
    shallow
  );
  const twoDates = TYPE_ENABLED.twoDates.indexOf(outing?.entity_type) > -1;
  const canSelectGroup =
    TYPE_ENABLED.groupSize.indexOf(outing?.entity_type) > -1;
  const canSelectEquipment =
    TYPE_ENABLED.equipmentType.indexOf(outing?.entity_type) > -1;
  const canSelectAccessible =
    TYPE_ENABLED.accessible.indexOf(outing?.entity_type) > -1;

  const showUpgrade =
    planSlug === 'pay-as-you-go' &&
    (campsites?.length > 0 || groupSize > 0 || equipmentType !== 'Tent');

  const trackerDetails = [
    {
      label: 'Campground',
      value: searchInput,
    },
    {
      label: 'Checkin Date',
      value: convertDateToUTC(new Date(checkin)).toDateString(),
    },
    {
      label: 'Checkout Date',
      value: convertDateToUTC(new Date(checkout)).toDateString(),
    },
    {
      label: 'Group Size',
      value: groupSize,
    },
    {
      label: 'Equipment Type',
      value: equipmentType,
    },
  ];
  return (
    <Flex
      direction={'column'}
      rowGap={2}
      bg="gray.100"
      px={4}
      py={2}
      my={2}
      borderRadius={'md'}
      boxShadow={'md'}
    >
      <Text fontSize={'xl'}>{outing.name}</Text>
      <SimpleGrid
        color={'gray.600'}
        fontSize={['sm', 'md']}
        columns={[2, 2, 2, 2, 2, 2]}
        spacing={2}
      >
        <Flex align="center" gridColumn="1 / span 2" minW="max-content">
          <CalendarIcon mr={3} color="brand.600"></CalendarIcon>
          <Text mr={[1, 3]}>{convertDateToUTC(checkin).toDateString()}</Text>
          {twoDates && (
            <>
              -
              <Text ml={[1, 3]} fontSize={['sm', 'md']}>
                {convertDateToUTC(checkout).toDateString()}
              </Text>
            </>
          )}
        </Flex>
        <Flex align="center">
          {canSelectGroup && (
            <>
              <Icon
                mr={3}
                color="brand.600"
                as={MdPeople}
                boxSize={[4, 6]}
              ></Icon>
              <Text>
                {groupSize} adult{groupSize > 1 ? 's' : ''}
              </Text>
            </>
          )}
        </Flex>
        <SelectedCampsites />
        <Flex align="center" color={'gray.600'}>
          {canSelectEquipment && (
            <>
              <Icon
                mr={3}
                color="brand.600"
                as={GiCampingTent}
                boxSize={[4, 6]}
              ></Icon>
              <Text>{equipmentType}</Text>
            </>
          )}
        </Flex>
        <Flex align="center" color={'gray.600'}>
          {canSelectAccessible && (
            <>
              <Icon
                mr={[3, 4]}
                color="brand.600"
                as={FaWheelchair}
                boxSize={[4, 5]}
              ></Icon>
              <Text>{accessible ? 'Yes' : 'No'}</Text>
            </>
          )}
        </Flex>
      </SimpleGrid>
      {/* {showUpgrade && (
            <Link
              fontSize={'sm'}
              textDecor="underline"
              fontStyle={'italic'}
              color="blue.500"
              onClick={() => setShowPricingModal(true)}
            >
              Upgrade to a subscription to choose specific sites, equipment type
              and group size
            </Link>
          )} */}
    </Flex>
  );
}

export default function OrderSummary() {
  const { code } = useUIStore((state) => ({ code: state.code }), shallow);
  if (code === 'YEAROFF') {
    return (
      <Flex w="100%" direction="column" mt={[0, 4]}>
        <Image
          // fallbackSrc="/public/camp.jpg"
          objectFit={'contain'}
          src={'/public/gift.jpeg'}
          m={2}
          my={[0, 6]}
          alignSelf={'stretch'}
          maxH="200px"
        />
        <Center>
          <Heading as={'h2'} fontSize="3xl" mt={[4, 10]}>
            {DISCOUNT_CODE[code].reviewButtonText}
          </Heading>
          <Text></Text>
        </Center>
      </Flex>
    );
  }
  return (
    <Flex direction={'column'}>
      <Heading fontWeight={500} fontSize={['2xl', '3xl']}>
        Order Summary:
      </Heading>
      <CampsiteSummary />
    </Flex>
  );
}
