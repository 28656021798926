import * as api from '../api.js';
import * as auth from '#actions/auth';

export const getAffiliate = async (set, get) => {
  const userId = get().user._id;
  const { success, failure } = await api.getAffiliate({
    userId,
  });
  if (failure) {
    return;
  }
  // set the affiliate
  set({ affiliate: success.affiliate });
  // updating the user
  auth.updateUser(set, get, { calle: 'getAffiliate' });
};
