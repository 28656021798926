import * as api from '../api.js';
import { SUB_STATUS } from '#constants';

export const updateSubStatus = async (
  set,
  get,
  { status, _id, period, paymentId, customPayment }
) => {
  const { success, failure } = await api.updateSubStatus({
    subscription: { status, _id },
    period,
    paymentId,
    customPayment,
  });
  if (failure) {
  }
  // refetch sub data
  return get().getSub();
};

export const getSub = async (set, get) => {
  const { success, failure } = await api.getSub();

  if (failure) {
    // get().setToast({
    //   title: "Can't fetch subscriptions",
    //   description: failure?.message,
    //   status: 'error',
    //   duration: 2000,
    //   isClosable: true,
    //   position: 'top',
    // });
    set({ subs: [], sub: {} }, false, { type: 'getSub' });
    return;
  }

  const { subs } = success;
  // Allow tracker creation for all of the following statuses
  const sub = subs.find(
    (s) =>
      s.status === SUB_STATUS.ACTIVE ||
      s.status === SUB_STATUS.PAUSE_REQUESTED ||
      s.status === SUB_STATUS.RESUME_REQUESTED ||
      s.status === SUB_STATUS.CANCEL_REQUESTED
  );
  set({ subs, sub }, false, { type: 'getSub' });
  return sub;
};
