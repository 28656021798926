import Rollbar from 'rollbar';

const env = process.env.REACT_APP_ENV;
const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  environment: env,
  enabled: env === 'dev' ? false : true,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: env,
    client: {
      javascript: {
        source_map_enabled: true, // false by default
        // Optionally have Rollbar guess which frames the error was
        // thrown from when the browser does not provide line
        // and column numbers.
        guess_uncaught_frames: true,
      },
    },
  },
};

const rollbar = new Rollbar(rollbarConfig);

export default rollbar;
