import { Flex, Icon, Stack, Text } from '@chakra-ui/react';
import {
  GiCampingTent,
  GiForestCamp,
  GiPalmTree,
  GiTicket,
} from 'react-icons/gi';

import { HiOutlineClock } from 'react-icons/hi';
import { IoMdHome } from 'react-icons/io';
import { MdTour } from 'react-icons/md';
import { shallow } from 'zustand/shallow';
import { toTitleCase } from '#utils';
import { useUIStore } from '#store';

function SuggestRowIcon(props) {
  const { rectype } = props;
  const style = {
    mx: 1.5,
    boxSize: 7,
  };
  if (rectype === 'campground') {
    return <Icon as={GiCampingTent} color="green" {...style} />;
  } else if (rectype === 'permit') {
    return <Icon as={GiTicket} color="olive" {...style} />;
  } else if (rectype === 'recarea') {
    return <Icon as={GiForestCamp} color="orange" {...style} />;
  } else if (rectype === 'tour') {
    return <Icon as={MdTour} color="orange" {...style} />;
  } else if (rectype === 'hotel') {
    return <Icon as={IoMdHome} color="orange" {...style} />;
  } else if (rectype === 'timedentry_tour') {
    return <Icon as={HiOutlineClock} color="orange" {...style} boxSize={6} />;
  } else {
    return <Icon as={GiPalmTree} color="orange.600" {...style} />;
  }
}

function handleEquipmentChange(setEquipmentType, outing) {
  if (outing.crawlerType === 'parks-canada') {
    setEquipmentType('Small Tent');
  } else {
    setEquipmentType('Tent');
  }
}

export default function SuggestRow(props) {
  const { name, isChild, type, outing } = props;
  const {
    searchInput,
    setOuting,
    setSearchInput,
    setShowSuggestions,
    resetCampsitesFilter,
    putEvent,
    setAlert,
    getCancellations,
    outing: currentOuting,
    setEquipmentType,
  } = useUIStore(
    (state) => ({
      searchInput: state.searchInput,
      setOuting: state.setOuting,
      setSearchInput: state.setSearchInput,
      setShowSuggestions: state.setShowSuggestions,
      resetCampsitesFilter: state.resetCampsitesFilter,
      putEvent: state.putEvent,
      setAlert: state.setAlert,
      getCancellations: state.getCancellations,
      outing: state.outing,
      setEquipmentType: state.setEquipmentType,
    }),
    shallow
  );
  const { parent_name, entity_type, state_code } = outing;
  const title = isChild || !state_code ? name : `${name}, ${state_code}`;
  const fontWeight = isChild ? 'normal' : 'semibold';

  const onRowClick = (e) => {
    if (type === 'recarea' && !isChild) {
      // Don't make recares clickable
      // Keep the dropdown open
      e.preventDefault();
    } else {
      setOuting(outing);
      // We might have to change the equipmentType also
      if (outing.crawlerType !== currentOuting.crawlerType) {
        // Now let's change the equipmentType
        handleEquipmentChange(setEquipmentType, outing);
      }
      getCancellations();
      setSearchInput(toTitleCase(name));
      setShowSuggestions(false);
      resetCampsitesFilter();
      putEvent({
        type: 'search',
        data: {
          query: searchInput,
          outingName: name,
          outingId: outing._id,
        },
      });
    }
  };
  return (
    <Flex
      align="center"
      direction="row"
      grow={0.1}
      p={1}
      pl={isChild ? 8 : 0}
      _hover={{
        background: type === 'recarea' && !isChild ? 'white' : 'blackAlpha.100',
        cursor: type === 'recarea' && !isChild ? 'not-allowed' : 'default',
      }}
      onMouseDown={onRowClick}
    >
      <SuggestRowIcon rectype={type} />
      <Stack spacing={0}>
        <Text color={isChild ? 'gray.800' : 'gray.700'} fontWeight={fontWeight}>
          {toTitleCase(title)}
        </Text>
        {/* {parent_name && (
          <Text fontSize="xs" color="gray.600">
            {toTitleCase(entity_type)}, {toTitleCase(parent_name)}
          </Text>
        )} */}
      </Stack>
    </Flex>
  );
}
