import * as api from '../api.js';

export const getStat = async ({ type }, set, get) => {
  const {
    success: { stat },
  } = await api.getStat({ type });
  set({ stats: [stat] }, false, { type: 'getStat' });
};

export const getCancellations = async (set, get) => {
  const outing = get().outing;
  const {
    success: { cancellations },
  } = await api.getCancellations({ outing });
  if (cancellations > 0) {
    set(
      {
        news: {
          text: `Good news! Last week there were ${cancellations} cancellations at ${outing.name}.`,
          show: true,
        },
      },
      false,
      {
        news: {
          text: `Good news! Last week there were ${cancellations} cancellations at ${outing.name}.`,
          show: true,
        },
      }
    );
  } else {
    set({
      news: { show: false },
    });
  }
};
