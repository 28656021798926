import {
  Alert,
  AlertDescription,
  AlertIcon,
  Avatar,
  Box,
  Button,
  CloseButton,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Heading,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';

import Campsites from '../Modals/Campsites.jsx';
import { ContactModal } from '../Contact/ContactModal.jsx';
import Entry from '../Entry.jsx';
import Logo from '../Logo.jsx';
import MobileNavBar from '../Nav/MobileNavBar.jsx';
import { NAV_LINKS } from '#constants';
import NavLink from '../Nav/NavLink.jsx';
import PricingModal from '../Modals/PricingModal.jsx';
import { Link as ReactLink } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { useEffect } from 'react';
import { useUIStore } from '#store';

export default function Navigation() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { updateUser, currentPage, alert, setFetchStatus, user, setAlert } =
    useUIStore(
      (state) => ({
        currentPage: state.currentPage,
        updateUser: state.updateUser,
        alert: state.alert,
        setAlert: state.setAlert,
        setFetchStatus: state.setFetchStatus,
        user: state.user,
      }),
      shallow
    );

  const fetchData = async () => {
    setFetchStatus('started');
    await updateUser();
    setFetchStatus('finished');
  };
  // Getting user from backend
  useEffect(() => {
    fetchData();
  }, []);

  const back = currentPage === 'about-us' ? 'transparent' : 'white';

  return (
    <Box bg={back} zIndex={3} position="relative">
      <ContactModal />
      <PricingModal />
      <Campsites />
      <Flex
        h={['60px', '70px']}
        alignItems={'center'}
        justifyContent={'space-between'}
        borderBottom="1px"
        borderBottomColor={'gray.200'}
        pr={[1, 4]}
      >
        <IconButton
          size={'md'}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label={'Open Menu'}
          display={{ md: 'none' }}
          onClick={isOpen ? onClose : onOpen}
          h="100%"
          bg="none"
        />
        <HStack spacing={8} alignItems={'center'} flex={'1 1 auto'}>
          <Logo />
          <HStack as={'nav'} display={{ base: 'none', md: 'flex' }} spacing={1}>
            {NAV_LINKS.map((link) => (
              <NavLink key={link.title} link={link.link}>
                {link.title}
              </NavLink>
            ))}
            <NavLink
              key={'Admin Dashboard'}
              link={'/admin/dashboard'}
              display={user?.role === 'admin' ? 'block' : 'none'}
              bg="brand.500"
              color="white"
              _hover={{
                bg: 'brand.400',
              }}
            >
              Admin Dashboard
            </NavLink>
          </HStack>
        </HStack>
        <Flex alignItems={'center'}>
          <Entry />
        </Flex>
      </Flex>
      {alert?.show && alert?.pages?.indexOf(currentPage) > -1 && (
        <Alert
          status={alert.status}
          position={'absolute'}
          justifyContent="center"
          // bottom={['-6em', '-42px']}
        >
          <AlertIcon boxSize={[4, 5]} display={['none', 'inherit']} />
          <AlertDescription fontSize={['xs', 'md']} mr={['2em', 0]}>
            {alert.text}
          </AlertDescription>
          <CloseButton
            alignItems="center"
            position="absolute"
            right={['0.5em', '2em']}
            onClick={() => setAlert({ show: false })}
          />
        </Alert>
      )}
      <MobileNavBar isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </Box>
  );
}
