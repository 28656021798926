import * as api from '../api.js';

export const createTracker = async (set, get, { subId }) => {
  const tracker = get().trackerInput;
  const { success, failure } = await api.createTracker({
    tracker: {
      ...tracker,
      subId,
      status: 'active',
    },
    outing: get().outing,
  });
  if (failure) {
    get().setToast({
      title: failure.message,
      description: 'Please reach out to us through chat.',
      status: 'error',
      duration: 3000,
      isClosable: true,
      position: 'top',
    });
    return { failure };
  }
  get().setToast({
    title: 'Tracker added',
    // description: "We've created your account for you.",
    status: 'success',
    duration: 2000,
    isClosable: true,
    position: 'top',
  });
  return { success };
};

export const getTrackers = async (
  set,
  get,
  {
    page = 0,
    size = 100,
    sortBy = 'createdAt',
    order = 'desc',
    adminView = false,
  }
) => {
  const { success, failure } = await api.getTrackers({
    page,
    size,
    sortBy,
    order,
    adminView,
  });
  if (failure) {
    get().setToast({
      title: "Can't fetch trackers",
      description: failure?.message,
      status: 'error',
      duration: 2000,
      isClosable: true,
      position: 'top',
    });
    set({ trackers: [] }, false, 'getTrackers');
    return;
  }
  set({ trackers: success.trackers }, false, 'getTrackers');
};

export const getTracker = async (set, get, { shortId }) => {
  const { success, failure } = await api.getTracker({ shortId });
  if (failure) {
    get().setToast({
      title: "Can't fetch tracker, please login",
      description: failure?.message,
      status: 'error',
      duration: 2000,
      isClosable: true,
      position: 'top',
    });
    return;
  }
  set({ tracker: success.tracker }, false, 'getTracker');
};

export const updateTracker = async (set, get, { tracker }) => {
  const { success, failure } = await api.updateTracker({
    tracker,
  });
  if (failure) {
  }
  // refetch trackers
  get().getTrackers({});
  return success;
};

export const updateTrackerStatus = async (set, get, { status, _id }) => {
  const { success, failure } = await api.updateTrackerStatus({
    tracker: {
      status,
      _id,
    },
  });
  if (failure) {
  }
  // refetch trackers
  get().getTrackers({});
};

export const updateTrackerShortStatus = async (
  set,
  get,
  { shortId, booked }
) => {
  const { success, failure } = await api.updateTrackerShortStatus({
    shortId,
    booked,
  });
  if (failure) {
  }
};
