import React, { useEffect, useState } from 'react';
import {
  FormControl,
  Button,
  Flex,
  HStack,
  Heading,
  Icon,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import { CHECKOUT_STAGES } from '#constants';
import { useNavigate } from 'react-router-dom';
import {
  PaymentElement,
  AddressElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { shallow } from 'zustand/shallow';
import { useUIStore } from '#store';
import SupportedCards from './SupportedCards.jsx';

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { setLoading, stripePayments, setStripePayments, onPaymentSuccess } =
    useUIStore(
      (state) => ({
        setLoading: state.setLoading,
        stripePayments: state.stripePayments,
        setStripePayments: state.setStripePayments,
        onPaymentSuccess: state.onPaymentSuccess,
      }),
      shallow
    );

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onSuccess = async ({ subId, paymentId }) => {
    await onPaymentSuccess({ subId, paymentId });
    // Step 4: Remove extra params from the URL
    navigate(`/checkout?success=true&subId=${subId}`);
  };

  async function handlePaymentLifeCycle() {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );
    const subId = new URLSearchParams(window.location.search).get('subId');
    const paymentId = new URLSearchParams(window.location.search).get(
      'paymentId'
    );

    if (!clientSecret) {
      return;
    }

    const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
    switch (paymentIntent.status) {
      case 'succeeded':
        await onSuccess({ subId, paymentId });
        break;
      case 'processing':
        setStripePayments({
          ...stripePayments,
          message: 'Your payment is processing. Please refresh...',
        });
        break;
      case 'requires_payment_method':
        setStripePayments({
          ...stripePayments,
          message: 'Your payment was not successful, please try again.',
        });
        break;
      default:
        setStripePayments({
          ...stripePayments,
          message: 'Something went wrong',
        });
        break;
    }
    setLoading(false);
  }

  useEffect(() => {
    handlePaymentLifeCycle();
  }, [stripe]);

  // Disable the loading screen.
  useEffect(() => {
    if (!elements) {
      setLoading(true);
    } else {
      elements.getElement('payment').on('ready', () => {
        setTimeout(() => setLoading(false), 200);
      });
    }
  }, [elements]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${process.env.REACT_APP_HOST}/checkout?subId=${stripePayments.subId}&paymentId=${stripePayments.paymentId}`,
      },
      // Let's keep users on the same page if we don't have to redirect.
      redirect: 'if_required',
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === 'card_error' || error.type === 'validation_error') {
        setMessage(error.message);
      } else {
        setMessage('An unexpected error occurred.');
      }
      setIsLoading(false);
      setLoading(false);
      return;
    }
    // This will be reached when redirect isn't used.
    await onSuccess({
      subId: stripePayments.subId,
      paymentId: stripePayments.paymentId,
    });
    setIsLoading(false);
    setLoading(false);
  };

  const paymentElementOptions = {
    layout: 'tabs',
  };

  return (
    <Flex direction={'column'} as="h3">
      <Flex mb={4} justifyContent={'space-between'}>
        <Heading fontWeight={500} fontSize={['2xl', '3xl']}>
          Card Details:
        </Heading>
        {/* <SupportedCards /> */}
      </Flex>
      <form
        id="payment-form"
        onSubmit={handleSubmit}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'stretch'}
      >
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <button
          id="submit"
          style={{ width: '100%' }}
          onClick={() => {
            setLoading(true);
          }}
        >
          <Button
            disabled={isLoading || !stripe || !elements}
            colorScheme="green"
            minW="300px"
            mt={8}
          >
            <span id="button-text">Pay now</span>
          </Button>
        </button>
        {/* Show any error or success messages */}
        {message && (
          <Text id="payment-message" mt={4} textAlign={'center'}>
            * {message}
          </Text>
        )}
      </form>
    </Flex>
  );
}
