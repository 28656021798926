import { getSuggestions } from '../api.js';
import { sortBy } from 'lodash-es';

export const suggestAction = async (set, get, { query }) => {
  const { success, failure } = await getSuggestions({
    query,
  });
  if (failure) {
  }
  const { data: outings } = success;

  // Format data into heirarchy
  const parents = {};
  const outingMap = outings.reduce((prev, curr) => {
    prev[curr.entity_id] = curr;
    return prev;
  }, {});

  // Finding all outings that are at the top level.
  outings.forEach((outing) => {
    if (!outing.parent_id || !(outing.parent_id in outingMap)) {
      parents[outing.entity_id] = {
        ...outing,
        children: [],
      };
    }
  });
  for (const outing of outings) {
    if (outing.parent_id) {
      if (outing.parent_id in parents) {
        // This is a child
        parents[outing.parent_id].children.push(outing);
      } else if (outing.parent_id in outingMap) {
        // This is a grand child
        const grandParentId = outingMap[outing.parent_id].parent_id;
        parents[grandParentId].children.push(outing);
      }
    }
  }
  const suggestedResults = sortBy(Object.values(parents), 'position');
  set(
    {
      suggestedResults,
      showSuggestions: true,
      noSearchResults: !suggestedResults?.length,
    },
    false,
    'search.suggestAction'
  );
};
