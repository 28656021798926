import { Helmet } from 'react-helmet';

export default function MetaInfo({ children, meta }) {
  const {
    title,
    summary,
    hero = 'https://reservenature.com/public/reserve-dark.png',
    url,
    type,
  } = meta;
  return (
    <Helmet>
      {children}
      <link rel="canonical" href={url} />
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta name="twitter:title" content={title}></meta>

      <meta name="description" content={summary} />
      <meta property="og:description" content={summary} />
      <meta name="twitter:description" content={summary} />

      <meta property="og:image" content={hero} />
      <meta name="twitter:image" content={hero} />

      <meta property="og:url" content={url} />
      <meta name="twitter:url" content={url}></meta>

      <meta property="og:type" content={type} />
    </Helmet>
  );
}
