import { BASIC_FEATURES, PAY_AS_YOU_GO_FEATURES } from '#constants';
import {
  Button,
  Divider,
  Flex,
  Heading,
  List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { IoCheckmarkCircleSharp, IoCloseCircleSharp } from 'react-icons/io5';

import { CHECKOUT_STAGES } from '#constants';
import PricingBody from '../Pricing/PricingBody.jsx';
import { shallow } from 'zustand/shallow';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUIStore } from '#store';

export default function PricingModal() {
  const showPricingModal = useUIStore((state) => state.showPricingModal);
  const togglePricingModal = useUIStore((state) => state.togglePricingModal);
  const {
    setPlanSlug,
    setShowPricingModal,
    sub,
    setCheckoutStage,
    planSlug,
    currentPage,
    putEvent,
    code,
  } = useUIStore(
    (state) => ({
      setPlanSlug: state.setPlanSlug,
      setShowPricingModal: state.setShowPricingModal,
      sub: state.sub,
      setCheckoutStage: state.setCheckoutStage,
      planSlug: state.planSlug,
      currentPage: state.currentPage,
      putEvent: state.putEvent,
      code: state.code,
    }),
    shallow
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (!showPricingModal) {
      return;
    }
    if (code === 'WINTER-FREE') {
      setShowPricingModal(false);
      navigate('/checkout');
      setCheckoutStage(CHECKOUT_STAGES[0]);
    }
    if (sub?._id) {
      setShowPricingModal(false);
      navigate('/checkout');
      setCheckoutStage(CHECKOUT_STAGES[0]);
    } else {
      putEvent({
        type: 'pricing-modal',
      });
    }
  }, [showPricingModal, sub]);

  const handleClick = (slug) => {
    setShowPricingModal(false);
    if (currentPage === 'checkout' && planSlug === slug) {
      // Nothing has changed
      return;
    }
    setPlanSlug(slug);
    navigate('/checkout');
    setCheckoutStage(CHECKOUT_STAGES[0]);
  };

  return (
    <Modal
      isOpen={showPricingModal}
      onClose={() => togglePricingModal(false)}
      size="5xl"
    >
      <ModalOverlay />
      <ModalContent bg="gray.100">
        {/* <ModalHeader>Select A Plan</ModalHeader> */}
        <ModalCloseButton />
        <ModalBody>
          <Flex mt={2}>
            <PricingBody onClick={handleClick} />
          </Flex>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}
