import * as api from '../api.js';

export const putEvent = async ({ type, data }, set, get) => {
  if (process.env.REACT_APP_ENV === 'dev') {
    return;
  }
  const outingId = get().outing?._id;
  const sessionId = get().sessionInfo?.id;
  const { success, failure } = await api.putEvent({
    type,
    sessionId,
    data: {
      ...data,
      outingId,
    },
  });
  if (failure) {
  }
};
