import * as api from '../api.js';

export const getCampsites = async (set, get) => {
  const outingId = get().outing?._id;
  if (!outingId) {
    return;
  }
  const { success, failure } = await api.getCampsites({
    outingId,
  });
  if (failure) {
    return;
  }
  const { campsites } = success;
  get().setCampsites(campsites);
};
