import * as api from '../api';

export const generateSitemap = async (set, get) => {
  const { success, failure } = await api.generateSitemap();
  if (failure) {
    return;
  }
  get().setToast({
    title: 'Created the new sitemap',
    status: 'success',
    duration: 2000,
    isClosable: true,
    position: 'top',
  });
};

export const getRecReview = async (set, get) => {
  const { success, failure } = await api.getRecReview();
  if (failure) {
    return;
  }
  set({ recReview: success }, false, 'getRecReview');
};

export const getCampReview = async (set, get) => {
  const { success, failure } = await api.getCampReview();
  if (failure) {
    return;
  }
  set({ campReview: success }, false, 'getCampReview');
};

export const updatedSafeCamps = async (set, get) => {
  const { success, failure } = await api.updatedSafeCamps();
  if (failure) {
    return;
  }
  get().setToast({
    title: 'Updated the camps',
    status: 'success',
    duration: 2000,
    isClosable: true,
    position: 'top',
  });
};

export const updatedSafePermits = async (set, get) => {
  const { success, failure } = await api.updatedSafePermits();
  if (failure) {
    return;
  }
  get().setToast({
    title: 'Updated the permits',
    status: 'success',
    duration: 2000,
    isClosable: true,
    position: 'top',
  });
};

export const updatedSafeOutings = async (set, get) => {
  const { success, failure } = await api.updatedSafeOutings();
  if (failure) {
    return;
  }
  get().setToast({
    title: 'Updated the outings',
    status: 'success',
    duration: 2000,
    isClosable: true,
    position: 'top',
  });
};

export const updatePermalinks = async (set, get) => {
  const { success, failure } = await api.updatePermalinks();
  if (failure) {
    return;
  }
  get().setToast({
    title: 'Added permalink',
    status: 'success',
    duration: 2000,
    isClosable: true,
    position: 'top',
  });
};

export const addYSLodges = async (set, get) => {
  const { success, failure } = await api.addYSLodges();
  if (failure) {
    return;
  }
  get().setToast({
    title: 'Added the Yellowstone links',
    status: 'success',
    duration: 2000,
    isClosable: true,
    position: 'top',
  });
};

export const addGCLodges = async (set, get) => {
  const { success, failure } = await api.addGCLodges();
  if (failure) {
    return;
  }
  get().setToast({
    title: 'Added the Grand Canyon links',
    status: 'success',
    duration: 2000,
    isClosable: true,
    position: 'top',
  });
};

export const syncTourSlots = async (set, get) => {
  const { success, failure } = await api.syncTourSlots();
  if (failure) {
    return;
  }
  get().setToast({
    title: 'Tour Slots Synced',
    status: 'success',
    duration: 2000,
    isClosable: true,
    position: 'top',
  });
};

export const saveRecReview = async ({ outing, recOuting }, set, get) => {
  const { success, failure } = await api.saveRecReview({ outing, recOuting });
  if (failure) {
    return;
  }
};

export const getPermitMapping = async ({ page, size }, set, get) => {
  const { success, failure } = await api.getPermitMapping({ page, size });
  if (failure) {
    return;
  }
  set({ permitMapping: success.permitMapping });
};

export const updatePermit = async ({ permit, crawlerType }, set, get) => {
  const { success, failure } = await api.updatePermit({ permit, crawlerType });
  if (failure) {
    return;
  }
  get().setToast({
    title: 'Updated Permit',
    status: 'success',
    duration: 2000,
    isClosable: true,
    position: 'top',
  });
};

export const updateOffer = async ({ emails }, set, get) => {
  const { success, failure } = await api.updateOffer({ emails });
  if (failure) {
    return;
  }
  get().setToast({
    title: `${success.updateCount} Emails added`,
    status: 'success',
    duration: 2000,
    isClosable: true,
    position: 'top',
  });
};

export const getDashData = async (set, get) => {
  const { success, failure } = await api.getDashData();
  if (failure) {
    return;
  }
  set({
    dashData: success,
  });
};
