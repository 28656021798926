import { PLAN_PROPERTIES_MAP, COUNTRY_CURRENCY_MAP } from '#constants';
import {
  Button,
  Flex,
  Heading,
  Image,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
} from '@chakra-ui/react';
import { IoCheckmarkCircleSharp, IoCloseCircleSharp } from 'react-icons/io5';
import { useEffect, useState } from 'react';

import { shallow } from 'zustand/shallow';
import { useUIStore } from '#store';

function SubscriptionSummary() {
  const { sub } = useUIStore((state) => ({ sub: state.sub }), shallow);
  const { plan } = sub;
  return (
    <Flex direction={'column'}>
      <Flex fontSize={'3xl'}>Currently Active Plan:</Flex>
      <PlanBox planSlug={plan.slug} />
    </Flex>
  );
}

function PlanBox({ planSlug }) {
  const { plans, location } = useUIStore(
    (state) => ({
      plans: state.plans,
      location: state.location,
    }),
    shallow
  );
  const plan = plans.find((plan) => plan.slug === planSlug);
  if (!plan) {
    return null;
  }
  // Get the currency of the user
  const currency = COUNTRY_CURRENCY_MAP[location.country];
  // Get cost according to currency
  const cost =
    plan.cost.find((c) => c.currency === currency)?.amount ??
    plan.cost[0].amount;

  const freqText =
    plan.cadence === 'MONTHLY'
      ? 'month'
      : plan.cadence === 'ANNUAL'
      ? 'year'
      : '';

  const { bgColor, features } = PLAN_PROPERTIES_MAP[planSlug];
  return (
    <Flex
      direction="column"
      p={2}
      py={[3, 6]}
      mx={[0, 5]}
      my={5}
      bgColor={bgColor}
      color="white"
      borderRadius={5}
    >
      <Heading size={['md', 'lg']} align="center" p={1}>
        {plan.name}
      </Heading>
      <Flex alignItems="baseline" justify="center">
        <Text fontSize={['xl', '2em']} alignItems={'baseline'}>
          $
        </Text>
        <Flex fontSize={['5xl', '6xl']}>
          <Text>{cost}</Text>
        </Flex>
        {freqText && <Text fontSize="1.2em">{freqText}</Text>}
        {/* <Text fontSize={['xs', 'sm']} ml={1}>
          {' '}
          ({plan.cost.amount} USD)
        </Text> */}
      </Flex>
      <List spacing={3} p={3}>
        {features.slice(0, 4).map((feature) => {
          return (
            <ListItem key={feature.text} display="flex">
              <ListIcon
                boxSize={[5, 6]}
                as={
                  feature.present ? IoCheckmarkCircleSharp : IoCloseCircleSharp
                }
                color={feature.present ? 'white' : 'black'}
              />
              <Text fontSize={['sm', 'md']}>{feature.text}</Text>
            </ListItem>
          );
        })}
      </List>
    </Flex>
  );
}

function Upgrade() {
  const { planSlug, setShowPricingModal, code } = useUIStore(
    (state) => ({
      planSlug: state.planSlug,
      setShowPricingModal: state.setShowPricingModal,
      code: state.code,
    }),
    shallow
  );
  if (code === 'YEAROFF') {
    return null;
  }
  const handleUpgrade = () => {
    setShowPricingModal(true);
  };
  if (!planSlug) return null;
  const { upgradeText, explainText } = PLAN_PROPERTIES_MAP[planSlug];
  return (
    <Flex direction="column" align="center">
      <Button
        onClick={handleUpgrade}
        colorScheme="brand"
        display="flex"
        flexDirection="column"
        p={8}
      >
        <Text>{upgradeText}</Text>
        {explainText && (
          <Text fontStyle="italic" fontSize="sm" mt={1}>
            {explainText}
          </Text>
        )}
      </Button>
    </Flex>
  );
}

export default function PlanSummary() {
  const { planSlug, sub, getPlans } = useUIStore((state) => state, shallow);
  useEffect(() => {
    getPlans();
  }, []);

  if (sub?._id) {
    return (
      <Flex direction="column" justify="center" w="100%">
        <SubscriptionSummary />
      </Flex>
    );
  }
  return (
    <Flex w="100%" direction="column" my={1}>
      <Text fontSize={['2xl', '3xl']}>Plan Summary:</Text>
      <Flex
        direction="column"
        justify="center"
        fontFamily="Roboto, Arial, sans-serif"
      >
        <PlanBox planSlug={planSlug} />
        <Upgrade />
      </Flex>
    </Flex>
  );
}
