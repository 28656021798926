import * as api from '../api.js';
import * as auth from '#actions/auth';

export const updateUserPref = async (set, get, { preference }) => {
  const userId = get().user._id;
  const { success, failure } = await api.updateUserPref({
    preference,
    userId,
  });
  if (failure) {
    return;
  }
  // updating the user
  auth.updateUser(set, get, { calle: 'updateUserPref' });
};
