import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Card,
  Flex,
  Icon,
  IconButton,
  Image,
  SimpleGrid,
  Skeleton,
  Stack,
  Tag,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { getPhotos } from '#services/flickr';

export default function FlickrImage({
  text,
  size,
  limit,
  fallbackSrc,
  ...style
}) {
  const [photo, setPhoto] = useState('');
  const [loading, setLoading] = useState(true);

  const fetchPhoto = async () => {
    setLoading(true);
    let p = fallbackSrc || '/public/camp.jpg';
    try {
      p = await getPhotos({ text, size, limit });
    } catch (err) {
      console.error(err);
    }
    setPhoto(p);
    setLoading(false);
  };

  useEffect(() => {
    if (text) fetchPhoto();
  }, [text]);

  return (
    <Skeleton isLoaded={!loading} {...style}>
      <Image src={photo} {...style} />
    </Skeleton>
  );
}
