import * as api from '../api.js';

export const getOuting = async ({ slug }, set, get) => {
  const { success, failure } = await api.getOuting({
    slug,
  });
  if (failure) {
    return;
  }
  const { outing } = success;
  get().setOuting(outing);
};

export const getNearbyOutings = async (filters, set, get) => {
  const { success, failure } = await api.getNearbyOutings(filters);
  if (failure) {
    return;
  }
  const { outings } = success;
  get().setNearbyOutings(outings);
};

export const getStateOutings = async ({ state }, set, get) => {
  const { success, failure } = await api.getStateOutings({ state });
  if (failure) {
    return;
  }
  const { campgrounds, popularCampgrounds, areas, activities } = success;
  get().setStateOutings({
    campgrounds,
    popularCampgrounds,
    areas,
    activities,
  });
};
