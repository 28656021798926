import { Box, Button, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { CHECKOUT_STAGES } from '#constants';
import MultiStep from 'src/components/Checkout/MultiStep.jsx';
import OrderSuccess from 'src/components/Checkout/OrderSuccess.jsx';
import Payment from 'src/components/Checkout/Payment.jsx';
import ReviewOrder from 'src/components/Checkout/ReviewOrder.jsx';
import { shallow } from 'zustand/shallow';
import { useUIStore } from '#store';
import { useSearchParams } from 'react-router-dom';
import Loading from 'src/components/Loading';
import MetaInfo from 'src/components/MetaInfo';

export default function Checkout() {
  // const [searchParams, setSearchParams] = useSearchParams();
  // const shouldSkipPayment = searchParams.get('skipPayment');
  const [searchParams] = useSearchParams();
  const paymentIntentClientSecret = searchParams.get(
    'payment_intent_client_secret'
  );
  const paymentSuccess = searchParams.get('success');
  const {
    checkoutStage,
    setCheckoutStage,
    sub,
    getPlans,
    setCurrentPage,
    putEvent,
    planSlug,
    user,
    toggleContactModal,
    setLoading,
    location,
    createPaymentIntent,
    removePaymentIntent,
  } = useUIStore(
    (state) => ({
      sub: state.sub,
      checkoutStage: state.checkoutStage,
      setCheckoutStage: state.setCheckoutStage,
      getPlans: state.getPlans,
      setCurrentPage: state.setCurrentPage,
      putEvent: state.putEvent,
      planSlug: state.planSlug,
      user: state.user,
      toggleContactModal: state.toggleContactModal,
      setLoading: state.setLoading,
      location: state.location,
      createPaymentIntent: state.createPaymentIntent,
      removePaymentIntent: state.removePaymentIntent,
    }),
    shallow
  );

  const handleUserState = async () => {
    // Handle no user which could happen:
    // 1. User directly goes to checkout
    // 2. User has a direct code and is looking to buy subscription only
    if (!user?._id) {
      toggleContactModal(true);
    }
  };

  useEffect(() => {
    handleUserState();
  }, [user?._id]);

  // Add checkout to events
  useEffect(() => {
    putEvent({
      type: 'checkout',
      data: {
        checkoutStage,
        planSlug,
      },
    });
  }, [checkoutStage]);

  // Set current page and get the plans
  useEffect(() => {
    setCurrentPage({ page: 'checkout' });
    getPlans();
  }, [sub?._id]);

  useEffect(() => {
    if (paymentIntentClientSecret) {
      // This was a redirect after payment.
      // enable loading screen
      setLoading(true);
      setCheckoutStage(CHECKOUT_STAGES[1]);
    } else if (paymentSuccess) {
      setCheckoutStage(CHECKOUT_STAGES[2]);
    }
  }, []);

  useEffect(() => {
    if (location?.updated && !paymentSuccess) {
      // Only create the payment intent once we have the location
      // and we haven't already paid this
      createPaymentIntent();
    }
    return () => {
      removePaymentIntent();
    };
  }, [location?.updated, planSlug]);

  const steps = [
    { title: 'Checkout', slug: 'review-order', component: <ReviewOrder /> },
    { title: 'Payment', slug: 'payment', component: <Payment /> },
    { title: 'Success', slug: 'success', component: <OrderSuccess /> },
  ];
  const handleStepClick = (step) => {
    if (step.slug === 'success') {
      return;
    }
    if (steps[currentIndex].slug === 'success') {
      return;
    }
    setCheckoutStage(step.slug);
  };
  const currentIndex = steps.findIndex((step) => step.slug === checkoutStage);
  const meta = {
    title: `Checkout: ${steps[currentIndex].title}`,
    summary: '',
    url: `https://reservenature.com/checkout`,
    type: 'website',
  };

  return (
    <Flex
      w="100%"
      direction={'column'}
      pt={[10, 4]}
      minH="80vh"
      // justifyContent={'center'}
    >
      <MetaInfo meta={meta}>
        <meta name="robots" content="noindex" />
      </MetaInfo>
      <Loading
        style={{
          bgColor: 'rgb(0,0,0, 0.1)',
          backdropFilter: 'blur(3px)',
        }}
      />
      <MultiStep
        steps={steps}
        currentIndex={currentIndex}
        onClick={handleStepClick}
      />
    </Flex>
  );
}
