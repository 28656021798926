import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  Image,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  VisuallyHidden,
  chakra,
  useColorModeValue,
} from '@chakra-ui/react';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
import { LOGO_URL_LIGHT, STATE_TITLE_MAP } from '#constants';

import { BrandHeading } from './BrandHeading';
import { BsHouseFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { capitalize, checkState } from '#utils';
import { useEffect, useMemo } from 'react';
import { useUIStore } from '#store';

const ListHeader = ({ children }) => {
  return (
    <Stack>
      <Flex fontWeight={'500'} fontSize={'lg'} mb={2}>
        {children}
      </Flex>
      <Divider borderColor={'brand.100'} w={32} />
    </Stack>
  );
};

const SocialButton = ({ children, label, to }) => {
  return (
    <Link to={to} rel="noopener noreferrer" target="_blank">
      <Button
        bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
        rounded={'full'}
        w={8}
        h={8}
        cursor={'pointer'}
        as={'a'}
        display={'inline-flex'}
        alignItems={'center'}
        justifyContent={'center'}
        transition={'background 0.3s ease'}
        _hover={{
          bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
        }}
      >
        <VisuallyHidden>{label}</VisuallyHidden>
        {children}
      </Button>
    </Link>
  );
};

function StateLink({ state }) {
  return (
    <Flex>
      <Link fontSize={'sm'} to={`/state/${state}`} preventScrollReset={false}>
        <Text noOfLines={1}>{STATE_TITLE_MAP[state]}</Text>
      </Link>
    </Flex>
  );
}

function OutingLink({ outing }) {
  return (
    <Flex>
      <Link
        fontSize={'md'}
        to={outing.permalink}
        _hover={{ textDecor: 'underline' }}
      >
        <Text noOfLines={1}>{capitalize(outing.name)}</Text>
      </Link>
    </Flex>
  );
}

function TopFooter() {
  const { getStateRecs, location, stateRecs, getCurrentLocation } = useUIStore(
    (state) => ({
      getStateRecs: state.getStateRecs,
      location: state.location,
      stateRecs: state.stateRecs,
      getCurrentLocation: state.getCurrentLocation,
    })
  );
  const stateCode = useMemo(
    () => checkState(location?.state),
    [location?.state]
  );

  useEffect(() => {
    getCurrentLocation();
  }, []);

  useEffect(() => {
    getStateRecs({ stateCode });
  }, [stateCode]);

  return (
    <Box bg={'#1c2e20'} color={'gray.100'}>
      <Container maxW={'7xl'}>
        <Flex direction="column" alignItems={'center'} py={10} px={4}>
          <Heading fontSize="3xl" mb={10}>
            Popular campgrounds in {stateCode}
          </Heading>
          <SimpleGrid
            columns={[1, 4]}
            alignSelf="center"
            spacingX={10}
            spacingY={2}
            _hover={{
              cursor: 'pointer',
            }}
          >
            {stateRecs.map((outing) => (
              <OutingLink outing={outing} key={outing._id}></OutingLink>
            ))}
          </SimpleGrid>
        </Flex>
      </Container>
    </Box>
  );
}

function MiddleFooter() {
  return (
    <Flex
      direction="column"
      alignItems={'center'}
      bg="gray.50"
      py={10}
      // color={'gray.100'}
      px={10}
    >
      <BrandHeading fontSize="3xl">
        Find a campground or permit in:{' '}
      </BrandHeading>
      <SimpleGrid
        columns={[3, 4, 7, 9]}
        alignSelf="center"
        spacingX={10}
        spacingY={2}
      >
        {Object.keys(STATE_TITLE_MAP).map((state) => (
          <StateLink state={state} key={state}></StateLink>
        ))}
      </SimpleGrid>
    </Flex>
  );
}

function BottomFooter() {
  return (
    <Box bg={'#1c2e20'} color={'gray.100'}>
      <Container as={Stack} maxW={'7xl'} py={10}>
        <Flex
          justifyContent={'space-between'}
          w="100%"
          flexDirection={['column', 'row']}
        >
          <VStack justifyContent={'center'} alignItems={'flex-start'}>
            <Image
              src={LOGO_URL_LIGHT}
              alt="Reserve Nature"
              boxSize={['60px', '70px']}
              mr={3}
              mb={2}
            />
            <Heading
              size={['md', 'lg']}
              cursor="pointer"
              display={'flex'}
              alignItems="center"
              fontFamily="sans-serif"
            >
              Reserve Nature
            </Heading>
          </VStack>
          <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={8}>
            <Stack align={'flex-start'}>
              <ListHeader>
                <Text>COMPANY</Text>
              </ListHeader>
              <Link to={'/about-us'}>About Us</Link>
              <Link to={'/blogs'}>Blogs</Link>
              <Link to={'/how-it-works'}>How It Works</Link>
              <Link to={'/pricing'}>Pricing</Link>
              <Link to={'/offers/money-back'}>Offers</Link>
            </Stack>

            <Stack align={'flex-start'}>
              <ListHeader>
                <Text>LEGAL</Text>
              </ListHeader>
              <Link to={'/cookies-policy'}>Cookies Policy</Link>
              <Link to={'/privacy-policy'}>Privacy Policy</Link>
              <Link to={'/terms-of-service'}>Terms of Service</Link>
            </Stack>
            <Stack spacing={4}>
              <ListHeader>
                <Text>CONTACT</Text>
              </ListHeader>
              <HStack alignItems="center" spacing={3}>
                <Icon as={BsHouseFill} boxSize={6} />
                <Text fontWeight={'medium'}>New York, NY, 11109, US</Text>
              </HStack>
              <HStack alignItems="center" spacing={3}>
                <Icon as={EmailIcon} boxSize={6} />
                <Text fontWeight={'medium'}>contact@reservenature.com</Text>
              </HStack>
              <HStack alignItems="center" spacing={3}>
                <Icon as={PhoneIcon} boxSize={6} />
                <Text fontWeight={'medium'}>+1 (669) 208-5607</Text>
              </HStack>
            </Stack>
          </SimpleGrid>
        </Flex>
      </Container>

      <Box
        borderTopWidth={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.700')}
      >
        <Container
          as={Stack}
          maxW={'6xl'}
          py={4}
          direction={{ base: 'column', md: 'row' }}
          spacing={4}
          justify={{ md: 'space-between' }}
          align={{ md: 'center' }}
        >
          <Text>© 2023 Reserve Nature. All rights reserved</Text>
          <Stack direction={'row'} spacing={6}>
            <SocialButton
              label={'Twitter'}
              to={'https://twitter.com/reservenature_'}
            >
              <Icon as={FaTwitter} />
            </SocialButton>
            {/* <SocialButton label={'YouTube'} to={'#'}>
              <Icon as={FaYoutube} />
            </SocialButton> */}
            <SocialButton
              label={'Instagram'}
              to={'https://www.instagram.com/reservenature_ofc/'}
            >
              <Icon as={FaInstagram} />
            </SocialButton>
            <SocialButton
              label={'Facebook'}
              to={'https://www.facebook.com/reservenature.ofc/'}
            >
              <Icon as={FaFacebook} />
            </SocialButton>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}

export default function Footer() {
  return (
    <Flex direction={'column'}>
      <TopFooter />
      <MiddleFooter />
      <BottomFooter />
    </Flex>
  );
}
