import TokenService from '#services/token';
import axios from 'axios';
import { server } from '#config';

const instance = axios.create({
  baseURL: server,
  timeout: 5000,
});

instance.interceptors.request.use((config) => {
  const token = TokenService.getLocalAccessToken();
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token;
  }
  return config;
});

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (err?.response?.status === 401) {
      // console.log(err);
      // we are in auth related issue category
      if (originalConfig.url === '/auth/refresh-token') {
        // refresh-token request has failed
        // We should log out the user
        TokenService.removeToken();
        TokenService.removeUser();
      } else if (originalConfig.url !== '/auth/google') {
        if (err?.response?.data?.failure?.message === 'jwt expired') {
          // The JWT has expired fetch a new token
          originalConfig._retry = true;
          try {
            const rs = await instance.post('/auth/refresh-token', {
              refreshToken: TokenService.getLocalRefreshToken(),
              email: TokenService.getUser().email,
            });
            const token = rs.data?.success;
            TokenService.setToken(token);
            return instance(originalConfig);
          } catch (_error) {
            return Promise.reject(_error.response);
          }
        } else if (
          err.response?.data?.failure?.message === 'No auth token' ||
          err.response?.data?.failure?.message ===
            'Incorrect email or refreshToken'
        ) {
          // We should log out the user
          TokenService.removeToken();
          TokenService.removeUser();
        }
      }
      return Promise.reject(err.response);
    } else {
      return Promise.resolve(err.response);
    }
  }
);

export const getSuggestions = async ({ query }) => {
  const resp = await instance.get(`/search?q=${query}`);
  return resp.data;
};

export const createTracker = async ({ tracker, outing }) => {
  const resp = await instance.post(`/trackers`, { tracker, outing });
  return resp.data;
};

export const oAuthLogin = async ({ credential }) => {
  const resp = await instance.post(`/auth/google`, {
    token: credential,
  });
  return resp.data;
};

export const registerContact = async ({ contact }) => {
  const resp = await instance.post(`/contact/register`, contact);
  return resp.data;
};

export const verifyContact = async ({ contact, code }) => {
  const resp = await instance.post(`/contact/verify`, { ...contact, code });
  return resp.data;
};

export const createPayment = async ({
  token,
  planSlug,
  address,
  locationId,
  verificationToken,
  paymentMode,
}) => {
  const body = {
    payment: {
      locationId,
      sourceId: token,
      verificationToken,
    },
    planSlug,
    address,
    paymentMode,
  };
  try {
    const resp = await instance.post('/payments/create', body);
    if (!resp) {
      return { failure: { message: "Can't verify the payment" } };
    }
    return resp.data;
  } catch (err) {
    return err.response.data;
  }
};

export const createPaymentIntent = async ({ planSlug, currency }) => {
  const body = {
    planSlug,
    currency,
  };
  const resp = await instance.post('/payments/intent', body);
  return resp.data;
};

export const subscribe = async ({
  token,
  planSlug,
  address,
  locationId,
  verificationToken,
  paymentMode,
}) => {
  const body = {
    payment: {
      locationId,
      // sourceId: process.env.REACT_APP_PUBLIC_CARD_NONCE
      //   ? process.env.REACT_APP_PUBLIC_CARD_NONCE
      //   : token,
      sourceId: token,
      verificationToken,
    },
    planSlug,
    address,
    paymentMode,
  };
  try {
    const resp = await instance.post('/payments/subscribe', body);
    return resp.data;
  } catch (err) {
    return err.response.data;
  }
};

export const createSub = async ({ sub }) => {
  const resp = await instance.post(`/subscriptions`, { sub });
  return resp.data;
};

export const createCodeSub = async ({ code, planSlug }) => {
  const resp = await instance.post(`/payments/code`, { code, planSlug });
  return resp.data;
};

export const getTrackers = async ({
  page = 0,
  size = 100,
  sortBy = 'createdAt',
  order = 'desc',
  adminView = false,
}) => {
  const resp = await instance.get(
    `/trackers?page=${page}&size=${size}&adminView=${adminView}&sortBy=${sortBy}&order=${order}`
  );
  return resp.data;
};

export const getTracker = async ({ shortId }) => {
  const resp = await instance.get(`/trackers/short/${shortId}`);
  return resp.data;
};

export const getSub = async () => {
  const resp = await instance.get(`/subscriptions`);
  return resp.data;
};

export const getUser = async () => {
  const resp = await instance.get(`/auth`);
  return resp.data;
};

export const getPlans = async ({ country }) => {
  const resp = await instance.get(`/plans/all?country=${country}`);
  return resp.data;
};

export const updateTracker = async ({ tracker }) => {
  const resp = await instance.put(`/trackers`, {
    tracker,
  });
  return resp.data;
};

export const updateTrackerStatus = async ({ tracker }) => {
  const resp = await instance.put(`/trackers`, {
    tracker,
  });
  return resp.data;
};

export const updateTrackerShortStatus = async ({ shortId, booked }) => {
  const resp = await instance.put(`/trackers/short/${shortId}`, {
    booked,
  });
  return resp.data;
};

export const getGiftCards = async () => {
  const resp = await instance.get(`/giftcards`);
  return resp.data;
};

export const updateSubStatus = async ({
  subscription,
  period,
  paymentId,
  customPayment,
}) => {
  const resp = await instance.post(`/subscriptions/update`, {
    subscription,
    period,
    paymentId,
    customPayment,
  });
  return resp.data;
};

export const getCampsites = async ({ outingId }) => {
  const resp = await instance.get(`/campsites?outingId=${outingId}`);
  return resp?.data ?? {};
};

export const getOuting = async ({ slug }) => {
  const resp = await instance.get(`/outings?slug=${slug}`);
  return resp.data;
};

export const getFlickrPhotoIds = async ({ text, limit = 10 }) => {
  let textToSearch = text;
  let resp = await axios.get(
    `https://www.flickr.com/services/rest/?method=flickr.photos.search&api_key=${process.env.REACT_APP_FLICKR_API_KEY}&text=${textToSearch}&format=json&nojsoncallback=1&geo_context=0&per_page=${limit}&orientation=landscape&sort=relevance`
  );
  if ((resp?.data?.photos?.total ?? 0) === 0) {
    // Shorten the text if no results
    textToSearch = text.split(' ').slice(0, 2).join(' ');
    textToSearch = textToSearch.replace(/[^a-zA-Z ]/g, '');
  }
  resp = await axios.get(
    `https://www.flickr.com/services/rest/?method=flickr.photos.search&api_key=${process.env.REACT_APP_FLICKR_API_KEY}&text=${textToSearch}&format=json&nojsoncallback=1&geo_context=0&per_page=${limit}&orientation=landscape&sort=relevance`
  );
  return resp.data;
};

export const getNearbyOutings = async ({ parentName, reservable }) => {
  const resp = await instance.get(
    `/outings?parentName=${parentName}&reservable=${reservable}`
  );
  return resp.data;
};

export const getStateOutings = async ({ state }) => {
  const resp = await instance.get(`/outings/state/${state}`);
  return resp.data;
};

export const getBlog = async ({ slug }) => {
  const resp = await instance.get(`/blogs/?slug=${slug}`);
  return resp.data;
};

export const getBlogs = async ({ page, size }) => {
  const resp = await instance.get(`/blogs/?page=${page}&size=${size}`);
  return resp.data;
};

export const upsertBlog = async ({ blog }) => {
  const resp = await instance.post(`/blogs`, { blog });
  return resp.data;
};

// ************* ADMIN **************** //

export const adminLogin = async ({ email, id, password }) => {
  const resp = await instance.post(`/auth/admin-login`, {
    email,
    id,
    password,
  });
  return resp.data;
};

export const generateSitemap = async () => {
  const resp = await instance.get(`/admin/generate-sitemap`);
  return resp.data;
};

export const getDashData = async () => {
  const resp = await instance.get(`/admin/dashboard`);
  return resp.data;
};

export const getRecReview = async () => {
  const resp = await instance.get(`/admin/rec-review`, {
    timeout: 20 * 60 * 1000,
  });
  return resp.data;
};

export const updatedSafeOutings = async () => {
  const resp = await instance.get(`/admin/rec-update-safe`, {
    timeout: 20 * 60 * 1000,
  });
  return resp.data;
};

export const saveRecReview = async ({ outing, recOuting }) => {
  const resp = await instance.post(`/admin/rec-review`, {
    outing,
    recOuting,
  });
  return resp.data;
};

// updates the campsites table
export const updatedSafeCamps = async () => {
  const resp = await instance.post(
    `/admin/camp-review`,
    {},
    {
      timeout: 20 * 60 * 1000,
    }
  );
  return resp.data;
};

export const updatedSafePermits = async () => {
  const resp = await instance.post(
    `/admin/permit-review`,
    {},
    {
      timeout: 20 * 60 * 1000,
    }
  );
  return resp.data;
};

export const getCampReview = async () => {
  const resp = await instance.get(`/admin/camp-review`, {
    timeout: 20 * 60 * 1000,
  });
  return resp.data;
};

export const updatePermalinks = async () => {
  const resp = await instance.post(
    `/admin/update-links`,
    {},
    {
      timeout: 20 * 60 * 1000,
    }
  );
  return resp.data;
};

export const addYSLodges = async () => {
  const resp = await instance.post(
    `/admin/ys-init`,
    {},
    {
      timeout: 20 * 60 * 1000,
    }
  );
  return resp.data;
};

export const addGCLodges = async () => {
  const resp = await instance.post(
    `/admin/gc-init`,
    {},
    {
      timeout: 20 * 60 * 1000,
    }
  );
  return resp.data;
};

export const syncTourSlots = async () => {
  const resp = await instance.post(
    `/admin/tour-slots-update-safe`,
    {},
    {
      timeout: 20 * 60 * 1000,
    }
  );
  return resp.data;
};

export const updatePermit = async ({ permit, crawlerType }) => {
  const resp = await instance.post(
    `/admin/permit-mapping`,
    {
      permit,
      crawlerType,
    },
    {
      timeout: 20 * 60 * 1000,
    }
  );
  return resp.data;
};

export const getPermitMapping = async ({ page, size }) => {
  const resp = await instance.get(
    `/admin/permit-mapping?page=${page}&size=${size}`,
    {
      timeout: 20 * 60 * 1000,
    }
  );
  return resp.data;
};

export const updateOffer = async ({ emails }) => {
  const resp = await instance.post(
    `/admin/update-offer`,
    {
      type: 'YEAROFF',
      emails,
    },
    {
      timeout: 20 * 60 * 1000,
    }
  );
  return resp.data;
};

// XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX //

// ************* EVENTS **************** //

export const postFeedback = async ({ description, name, tracker, user }) => {
  const resp = await instance.post(`/feedbacks`, {
    description,
    name,
    tracker,
    user,
  });
  return resp.data;
};

export const getFeedbacks = async () => {
  const resp = await instance.get(`/feedbacks`);
  return resp.data;
};

// XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX //

// ************* EVENTS **************** //

export const putEvent = async ({ type, data, sessionId }) => {
  const resp = await instance.put(`/events`, {
    type,
    data,
    sessionId,
  });
  return resp.data;
};

export const backfillEvents = async ({ sessionId }) => {
  const resp = await instance.post(`/events/backfill`, {
    sessionId,
  });
  return resp.data;
};

// XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX //

// ************* EVENTS **************** //

export const getStat = async ({ type }) => {
  const resp = await instance.get(`/stats?type=${type}`);
  return resp.data;
};

export const getCancellations = async ({ outing }) => {
  const resp = await instance.get(
    `/stats/cancellations?outingId=${outing._id}`
  );
  return resp.data;
};

// XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX //

// ************* USER **************** //

export const updateUserPref = async ({ preference, userId }) => {
  const resp = await instance.patch(`/users/${userId}`, { preference });
  return resp.data;
};

// XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX //

export const getAffiliate = async ({ userId }) => {
  const resp = await instance.post(`/affiliate`);
  return resp.data;
};

export const getCurrentLocation = async () => {
  // return {
  //   state_prov: 'Southland',
  //   city: 'Something',
  //   country_code2: 'AU',
  // };
  const resp = await axios.get(
    `https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.REACT_APP_IP_GEO_LOCATION_KEY}`
  );
  return resp.data;
};

export const getStateRecs = async ({ stateCode }) => {
  const resp = await instance.get(`/recoms/states/${stateCode}`);
  return resp.data;
};

// ************* SCAN **************** //

export const getScans = async ({ trackerId, page, size }) => {
  let params = `page=${page}&size=${size}`;
  if (trackerId) {
    params = `${params}&trackerId=${trackerId}`;
  }
  const resp = await instance.get(`/scans?${params}`);
  return resp.data;
};

// XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX //

// ************* CHAT **************** //

export const chat = async ({ messages }) => {
  const resp = await instance.post(`/support/chat`, { messages });
  return resp.data;
};

// XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX //
