import * as admin from '#actions/admin';
import * as affiliate from '#actions/affiliate';
import * as auth from '#actions/auth';
import * as blogAPI from '#actions/blog';
import * as campsite from '#actions/campsite';
import * as payment from '#actions/payment';
import * as contact from '#actions/contact';
import * as event from '#actions/event';
import * as feedback from '#actions/feedback';
import * as gc from '#actions/giftCards';
import * as outing from '#actions/outing';
import * as plans from '#actions/plan';
import * as scan from '#actions/scan';
import * as search from '#actions/search';
import * as stat from '#actions/stat';
import * as sub from '#actions/sub';
import * as trackerAction from '#actions/tracker';
import * as user from '#actions/user';
import * as util from '#actions/util';
import * as support from '#actions/support';

import { CHECKOUT_STAGES, INITIAL_STATE } from '#constants';

import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { getTimeDifferenceInHours } from '#utils';
import { persist } from 'zustand/middleware';
import produce from 'immer';
import { v4 as uuidv4 } from 'uuid';

const store = (set, get) => ({
  user: null,
  sub: {},
  subs: [],
  plans: [],
  steps: [], // tells what the next step is going to be
  setSteps: (steps) => set({ steps }, false, { type: 'setSteps', steps }),
  getPlans: () => plans.getPlans(set, get),
  setUser: (user) => {
    set({ user }, false, { type: 'setUser', user });
  },
  updateUser: ({ user, calle } = {}) =>
    auth.updateUser(set, get, { user, calle }),
  planSlug: '',
  setPlanSlug: (slug) =>
    set({ planSlug: slug }, false, { type: 'setPlanSlug', slug }),
  trackErrors: {},
  setTrackErrors: (trackErrors) =>
    set({ trackErrors }, false, { type: 'setTrackErrors', trackErrors }),
  loading: null,
  setLoading: (loading) =>
    set({ loading }, false, { type: 'setLoading', loading }),
  fetchStatus: null,
  setFetchStatus: (fetchStatus) =>
    set({ fetchStatus }, false, { type: 'setFetchStatus', fetchStatus }),
  entryModal: false,
  toggleEntryModal: (entryModal) =>
    set({ entryModal: entryModal ?? !get().entryModal }, false, {
      type: 'setEntryModal',
      entryModal,
    }),
  searchInput: '',
  trackerInput: INITIAL_STATE.trackerInput,
  setSearchInput: (val) => {
    set({ searchInput: val }, false, 'setSearchInput');
  },
  outing: {},
  setOuting: (outing) => set({ outing }, false, 'setOuting'),
  setStartDate: (val) => {
    set(
      produce((state) => {
        state.trackerInput.startDate = val;
      }),
      false,
      'setStartDate'
    );
  },
  setEndDate: (val) => {
    set(
      produce((state) => {
        state.trackerInput.endDate = val;
      }),
      false,
      'setEndDate'
    );
  },
  setEquipmentType: (val) => {
    set(
      produce((state) => {
        state.trackerInput.equipmentType = val;
      }),
      false,
      'setEquipmentType'
    );
  },

  setGroupSize: (val) => {
    set(
      produce((state) => {
        state.trackerInput.groupSize = val;
      }),
      false,
      'setGroupSize'
    );
  },
  setFlexiDays: (val) => {
    set(
      produce((state) => {
        state.trackerInput.flexiDaysBefore = val;
        state.trackerInput.flexiDaysAfter = val;
      }),
      false,
      'setFlexiDays'
    );
  },
  setAccessible: (val) => {
    set(
      produce((state) => {
        state.trackerInput.filters.accessible = val;
      }),
      false,
      { type: 'setAccessible', val }
    );
  },
  setSplitStay: (val) => {
    set(
      produce((state) => {
        state.trackerInput.splitStay = val;
      }),
      false,
      { type: 'splitStay', val }
    );
  },
  addCampsitesToFilter: (val) => {
    set(
      produce((state) => {
        state.trackerInput.filters.campsites.push(val);
      }),
      false,
      { type: 'addCampsitesToFilter', val }
    );
  },
  removeCampsitesFromFilter: (val) => {
    set(
      produce((state) => {
        const campsites = state.trackerInput.filters.campsites;
        const campIndex = campsites?.indexOf(val);
        campsites.splice(campIndex, 1);
      }),
      false,
      { type: 'removeCampsitesFromFilter', val }
    );
  },
  resetCampsitesFilter: () => {
    set(
      produce((state) => {
        state.trackerInput.filters.campsites = [];
      }),
      false,
      { type: 'resetCampsitesFilter' }
    );
  },
  setNotificationPreference: (val) => {
    set(
      produce((state) => {
        state.trackerInput.notificationPref = val;
      }),
      false,
      { type: 'setNotificationPreference' }
    );
  },
  suggestedResults: [],
  getSuggestedResults: (query) => search.suggestAction(set, get, { query }),
  noSearchResults: false,
  showSuggestions: false,
  setShowSuggestions: (val) => {
    set({ showSuggestions: val }, false, 'setShowSuggestions');
  },
  showContactModal: false,
  contactModalError: '',
  toggleContactModal: (showContactModal) =>
    set(
      { showContactModal: showContactModal ?? !get().showContactModal },
      false,
      {
        type: 'toggleEntryModal',
        showContactModal,
      }
    ),
  contactStatus: null,
  setContactStatus: (status) =>
    set({ contactStatus: status }, false, 'setContactStatus'),
  contactInput: {
    contactId: '',
    type: 'phone',
  },
  setContactIdInput: (contactId) => {
    set(
      produce((state) => {
        state.contactInput.contactId = contactId;
      }),
      false,
      {
        type: 'setContactInput',
        contactId,
      }
    );
  },
  showPricingModal: false,
  setShowPricingModal: (val) => {
    set({ showPricingModal: val }, false, 'setShowPricingModal');
  },
  togglePricingModal: (showPricingModal) =>
    set(
      { showPricingModal: showPricingModal ?? !get().showPricingModal },
      false,
      {
        type: 'togglePricingModal',
        showPricingModal,
      }
    ),
  registerContact: () => contact.register(set, get),
  verifyContact: ({ otp }) => contact.verifyContact({ otp }, set, get),
  createTracker: ({ subId }) =>
    trackerAction.createTracker(set, get, { subId }),
  oAuthLogin: (credential) => auth.oAuthLogin(set, get, { credential }),
  adminLogin: ({ email, id, password }) =>
    auth.adminLogin(set, get, { email, id, password }),
  logoutUser: () => auth.logoutUser(set, get),
  toast: {},
  setToast: (toast) => set({ toast }, false, { type: 'setToast', toast }),
  codeCheckout: () => payment.codeCheckout(set, get),
  card: null,
  setCard: (card) => set({ card }, false, { type: 'setCard', card }),
  cardError: '',
  setCardError: (cardError) =>
    set({ cardError }, false, { type: 'setCardError', cardError }),
  paymentMode: 'card',
  setPaymentMode: (mode) =>
    set({ paymentMode: mode }, false, { type: 'setPaymentMode', mode }),
  createSub: (sub) => sub.create(set, get, { sub }),
  address: {
    firstName: '',
    lastName: '',
    line1: '',
    line2: '',
    state: '',
    country: 'US',
    city: '',
    zipcode: '',
  },
  setAddress: (address) =>
    set({ address }, false, { type: 'setAddress', address }),
  getTrackers: ({ page, size, sortBy, order, adminView } = {}) =>
    trackerAction.getTrackers(set, get, {
      page,
      size,
      sortBy,
      order,
      adminView,
    }),
  getTracker: ({ shortId }) => trackerAction.getTracker(set, get, { shortId }),
  trackers: [],
  tracker: {},
  updateTrackerStatus: ({ status, _id }) =>
    trackerAction.updateTrackerStatus(set, get, { status, _id }),
  updateTracker: ({ tracker }) =>
    trackerAction.updateTracker(set, get, { tracker }),
  updateTrackerShortStatus: ({ shortId, booked }) =>
    trackerAction.updateTrackerShortStatus(set, get, { shortId, booked }),
  updateSubStatus: ({ status, _id, period, paymentId, customPayment }) =>
    sub.updateSubStatus(set, get, {
      status,
      _id,
      period,
      paymentId,
      customPayment,
    }),
  getSub: () => sub.getSub(set, get),
  payments: {},
  setPayments: (payments) =>
    set({ payments }, false, { type: 'setPayments', payments }),
  stripePayments: {
    clientSecret: null,
    message: null,
  },
  setStripePayments: (stripePayments) =>
    set({ stripePayments }, false, { type: 'stripePayments', stripePayments }),
  createPaymentIntent: () => payment.createPaymentIntent(set, get),
  removePaymentIntent: () => payment.removePaymentIntent(set, get),
  onPaymentSuccess: ({ subId, paymentId }) =>
    payment.onPaymentSuccess(set, get, { subId, paymentId }),
  profileTab: 'trackers',
  setProfileTab: (profileTab) =>
    set({ profileTab }, false, { type: 'setProfileTab', profileTab }),
  giftCards: [],
  getGiftCards: () => gc.getGiftCards(set, get),
  checkoutStage: CHECKOUT_STAGES[0],
  setCheckoutStage: (stage) =>
    set({ checkoutStage: stage }, false, { type: 'setCheckoutStage', stage }),
  reviewOrder: () => payment.reviewOrder(set, get),
  checkout: {},
  campsites: [],
  getCampsites: () => campsite.getCampsites(set, get),
  setCampsites: (campsites) =>
    set({ campsites }, false, { type: 'setCampsites', campsites }),
  campsitesModal: false,
  toggleCampsitesModal: (campsitesModal) =>
    set({ campsitesModal: campsitesModal ?? !get().campsitesModal }, false, {
      type: 'toggleCampsitesModal',
      campsitesModal,
    }),
  getOuting: ({ slug }) => outing.getOuting({ slug }, set, get),
  getNearbyOutings: (filters) => outing.getNearbyOutings(filters, set, get),
  nearbyOutings: [],
  setNearbyOutings: (nearbyOutings) =>
    set({ nearbyOutings }, false, 'setNearbyOuting'),
  stateInfo: {
    areas: [],
    popularCampgrounds: [],
    campgrounds: [],
    // popularActivities: [],
    activities: [],
  },
  getStateOutings: ({ state }) => outing.getStateOutings({ state }, set, get),
  setStateOutings: (data) =>
    set({ stateInfo: data }, false, { type: 'setStateOutings' }),
  blog: {
    title: '',
    author: '',
    avatar: '',
    thumbnail: '',
    hero: '',
    summary: '',
    tags: [],
    content: '',
    isPublished: false,
  },
  blogs: [],
  setBlog: (blog) => set({ blog }, false, 'setBlog'),
  setBlogs: (blogs) => set({ blogs }, false, 'setBlogs'),
  upsertBlog: ({ blog }) => blogAPI.upsertBlog({ blog }, set, get),
  getBlog: ({ slug }) => blogAPI.getBlog({ slug }, set, get),
  getBlogs: ({ page, size }) => blogAPI.getBlogs({ page, size }, set, get),
  generateSitemap: () => admin.generateSitemap(set, get),
  currentPage: '',
  setCurrentPage: ({ page }) =>
    set({ currentPage: page }, false, 'currentPage'),
  updateUserPref: ({ preference }) =>
    user.updateUserPref(set, get, { preference }),
  getAffiliate: () => affiliate.getAffiliate(set, get),
  affiliate: {},
  location: INITIAL_STATE.location,
  getCurrentLocation: () => util.getCurrentLocation(set, get),
  stateRecs: [],
  getStateRecs: ({ stateCode }) => util.getStateRecs(set, get, { stateCode }),
  tawk: null,
  setTawk: (tawkRef) => set({ tawk: tawkRef }, false, 'setTawk'),
  recReview: {},
  getRecReview: () => admin.getRecReview(set, get),
  updatedSafeOutings: () => admin.updatedSafeOutings(set, get),
  saveRecReview: ({ outing, recOuting }) =>
    admin.saveRecReview({ outing, recOuting }, set, get),
  campReview: {},
  getCampReview: () => admin.getCampReview(set, get),
  updatedSafeCamps: () => admin.updatedSafeCamps(set, get),
  updatedSafePermits: () => admin.updatedSafePermits(set, get),
  updatePermalinks: () => admin.updatePermalinks(set, get),
  addYSLodges: () => admin.addYSLodges(set, get),
  addGCLodges: () => admin.addGCLodges(set, get),
  syncTourSlots: () => admin.syncTourSlots(set, get),
  permitMapping: [],
  getPermitMapping: ({ page, size }) =>
    admin.getPermitMapping({ page, size }, set, get),
  updatePermit: ({ permit, crawlerType }) =>
    admin.updatePermit({ permit, crawlerType }, set, get),
  updateOffer: ({ emails }) => admin.updateOffer({ emails }, set, get),
  getDashData: () => admin.getDashData(set, get),
  dashData: {},
  putEvent: ({ type, data }) => event.putEvent({ type, data }, set, get),
  postFeedback: ({ description, name, user, tracker }) =>
    feedback.postFeedback({ description, name, user, tracker }, set, get),
  fetchTestimonials: () => feedback.fetchTestimonials(set, get),
  getStat: ({ type }) => stat.getStat({ type }, set, get),
  stats: [],
  // Scans
  scans: {
    page: 0,
    total: 0,
    size: 0,
    data: [],
  },
  getScans: ({ trackerId, page, size }) =>
    scan.getScans({ trackerId, page, size }, set, get),
  alert: {
    show: false,
    pages: [],
    text: '',
    status: '',
  },
  setAlert: (alert) => set({ alert }, false, { type: 'setAlert', alert }),
  code: '',
  setCode: (code) => set({ code }, false, { type: 'setCode', code }),
  selectedTracker: {},
  setSelectedTracker: (tracker) =>
    set({ selectedTracker: tracker }, false, {
      type: 'setSelectedTracker',
      tracker,
    }),
  sessionInfo: INITIAL_STATE.sessionInfo,
  resetStore: () => util.resetStore(set, get),
  showSubActionModal: false,
  toggleSubActionModal: (showSubActionModal) =>
    set(
      { showSubActionModal: showSubActionModal ?? !get().showSubActionModal },
      false,
      {
        type: 'toggleEntryModal',
        showSubActionModal,
      }
    ),
  editModal: false,
  toggleEditModal: (editModal) =>
    set({ editModal: editModal ?? !get().editModal }, false, {
      type: 'setEditModal',
      editModal,
    }),
  news: {
    show: false,
    text: '',
  },
  setNews: (news) => set({ news }, false, { type: 'setNews', news }),
  getCancellations: () => stat.getCancellations(set, get),
  chat: ({ messages }) => support.chat({ messages }, set, get),
});

const immer = (config) => (set, get, api) =>
  config(
    (partial, replace) => {
      const nextState =
        typeof partial === 'function' ? produce(partial) : partial;
      return set(nextState, replace);
    },
    get,
    api
  );

const persistParams = {
  name: 'reserve-nature',
  partialize: (state) => ({
    // Make sure you  are resetting all these in logout function
    planSlug: state.planSlug,
    trackerInput: state.trackerInput,
    searchInput: state.searchInput,
    outing: state.outing,
    sessionInfo: state.sessionInfo,
    user: state.user,
  }),
  merge: (persistedState, currentState) => {
    // Handle date merges
    const newState = { ...currentState, ...persistedState };
    const {
      trackerInput: { startDate, endDate },
    } = persistedState;
    if (startDate) {
      newState.trackerInput.startDate = new Date(startDate);
    }
    if (startDate) {
      newState.trackerInput.endDate = new Date(endDate);
    }
    const sessionInfo = persistedState.sessionInfo ?? {};
    sessionInfo.createdAt = new Date(sessionInfo.createdAt);
    if (!sessionInfo.id) {
      sessionInfo.id = uuidv4();
      sessionInfo.createdAt = new Date();
    }
    newState.sessionInfo = sessionInfo;
    return newState;
  },
};

// const useUIStore = create(devtools(persist(immer(store), persistParams)));
export const useUIStore = create(devtools(persist(store, persistParams)));
