import * as api from '../api.js';

export const upsertBlog = async ({ blog }, set, get) => {
  const { success, failure } = await api.upsertBlog({
    blog,
  });
  if (failure) {
    get().setToast({
      title: 'Failed to save',
      status: 'error',
      duration: 2000,
      isClosable: true,
      position: 'top',
    });
    return;
  }
  const { blog: resBlog } = success;
  get().setToast({
    title: 'Updated the blog',
    status: 'success',
    duration: 2000,
    isClosable: true,
    position: 'top',
  });
  get().setBlog(resBlog);
};

export const getBlog = async ({ slug }, set, get) => {
  const { success, failure } = await api.getBlog({
    slug,
  });
  if (failure) {
    return;
  }
  const { blog } = success;
  get().setBlog(blog);
};

export const getBlogs = async ({ page = 0, size = 100 }, set, get) => {
  const { success, failure } = await api.getBlogs({
    page,
    size,
  });
  if (failure) {
    return;
  }
  const { blogs } = success;
  get().setBlogs(blogs);
};
