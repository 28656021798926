import * as api from '../api.js';

export const register = async (set, get) => {
  const contact = get().contactInput;
  const { success, failure } = await api.registerContact({
    contact: {
      contactId: `+${contact.contactId}`,
      type: contact.type,
    },
  });
  if (failure) {
  }
  const { user } = success;
  get().updateUser({ user, calle: 'register' });
  get().setContactStatus('verify');
};

export const verifyContact = async ({ otp }, set, get) => {
  const contact = get().contactInput;
  const { success, failure } = await api.verifyContact({
    contact: {
      contactId: `+${contact.contactId}`,
      type: contact.type,
    },
    code: otp,
  });
  if (failure) {
    set({ contactModalError: 'Invalid OTP' }, false, { type: 'verify' });
    set({ otp: '' }, false, { type: 'verify' });
    return { failure };
  }
  const { user } = success;
  get().updateUser({ user, calle: 'verify' });
  get().setContactStatus(null);
  return { success };
};
