import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { SignUp } from './SignUp.jsx';
import { RegisterContact } from './RegisterContact.jsx';

import { shallow } from 'zustand/shallow';
import { useUIStore } from '#store';

export function ContactModal() {
  const {
    showContactModal,
    toggleContactModal,
    user,
    steps,
    currentPage,
    code,
    setShowPricingModal,
  } = useUIStore(
    (state) => ({
      showContactModal: state.showContactModal,
      toggleContactModal: state.toggleContactModal,
      user: state.user,
      steps: state.steps,
      currentPage: state.currentPage,
      code: state.code,
      setShowPricingModal: state.setShowPricingModal,
    }),
    shallow
  );
  let shownComponent = <SignUp />;
  if (user?._id) {
    shownComponent = <RegisterContact />;
  }
  const isUserVerified = () => {
    if (!user?.contacts?.[0]) return false;
    return user.contacts.some((c) => c.verified);
  };
  useEffect(() => {
    if (showContactModal && isUserVerified()) {
      toggleContactModal(false);
      // Do the next things in steps
      if (steps?.[0] === 'pricingModal') {
        if (currentPage !== 'profile' && code !== 'YEAROFF') {
          // Don't show pricing in case of affiliates
          setShowPricingModal(true);
        }
      }
    }
  }, [showContactModal, user]);
  return (
    <Modal
      isOpen={showContactModal}
      onClose={() => toggleContactModal(false)}
      size="5xl"
    >
      <ModalOverlay />
      <ModalContent bg="gray.100">
        {shownComponent}
        <ModalCloseButton color={'white'} />
      </ModalContent>
    </Modal>
  );
}
