import * as api from '../api.js';

export const getScans = async ({ trackerId, page, size }, set, get) => {
  const { success, failure } = await api.getScans({ page, size, trackerId });
  if (failure) {
    return;
  }
  for (const scan of success?.scans?.data ?? []) {
    scan.scannedAt = new Date(scan.scannedAt);
    scan.tracker.startDate = new Date(scan.tracker.startDate);
    scan.tracker.endDate = new Date(scan.tracker.endDate);
  }
  set({ scans: success.scans }, false, { type: 'getScans' });
};
