import { ArrowForwardIcon, CalendarIcon } from '@chakra-ui/icons';
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  HStack,
  Icon,
  Input,
  Select,
  Text,
  useOutsideClick,
} from '@chakra-ui/react';
import { TYPE_ENABLED } from '#constants';
import { MdArrowDropDown, MdPeople } from 'react-icons/md';
import { useCallback, useEffect, useRef, useState } from 'react';

import DateRangeSelector from './DateRangeSelector.jsx';
import Suggest from './Suggest';
import { debounce } from 'lodash-es';
import { shallow } from 'zustand/shallow';
import { useUIStore } from '#store';

export default function DateWidget({ editMode = false }) {
  const { startDate, endDate, flexiDaysBefore, flexiDaysAfter } = useUIStore(
    (state) => state.trackerInput,
    shallow
  );
  const ref = useRef();
  const { setStartDate, setEndDate, trackErrors, outing, setFlexiDays } =
    useUIStore(
      (state) => ({
        setStartDate: state.setStartDate,
        setEndDate: state.setEndDate,
        trackErrors: state.trackErrors,
        outing: state.outing,
        setFlexiDays: state.setFlexiDays,
      }),
      shallow
    );
  const [showCal, setShowCal] = useState(false);

  // Should we only take 1 date as input
  const twoDates = TYPE_ENABLED.twoDates.indexOf(outing?.entity_type) > -1;
  const canSelectFlexiDates =
    TYPE_ENABLED.flexiDates.indexOf(outing?.entity_type) > -1;

  useEffect(() => {
    // Don't reset in editMode
    if (!twoDates && !editMode) {
      setFlexiDays(0);
      setEndDate(startDate);
    }
  }, [outing?.entity_type]);

  const setDates = (dates) => {
    const [sD, eD] = dates;
    setStartDate(sD);
    // Only set the end date if it's different from start in case of twoDates
    if (twoDates && eD.getTime() !== sD.getTime()) {
      setEndDate(eD);
    }
    if (!twoDates) {
      setEndDate(eD);
    }
  };
  const toggleCal = (val) => {
    setShowCal(val);
  };
  useOutsideClick({
    ref: ref,
    handler: () => toggleCal(false),
  });

  const defaultRange = startDate ? [startDate, endDate] : [];

  let outline = 'none';
  if (trackErrors.date) {
    outline = 'red auto 1px';
  }
  if (showCal) {
    outline = '-webkit-focus-ring-color auto 1px';
  }

  return (
    <Flex
      align={'flex-start'}
      direction="column"
      w={['100%', 'unset']}
      ref={ref}
    >
      <>
        <Flex w={['100%', 'unset']}>
          <Text
            mx={3}
            my={2}
            color="blackAlpha.900"
            fontFamily="Roboto, Arial, sans-serif"
            borderRadius={8}
            borderWidth={1}
            borderColor="blackAlpha.200"
            w={['100%', '350px']}
            p={2}
            display="flex"
            justifyContent={'space-between'}
            alignItems="center"
            cursor={'pointer'}
            outline={outline}
            onClick={() => toggleCal(true)}
          >
            {startDate?.toDateString() ?? 'Checkin Date'}
            {twoDates && (
              <>
                <ArrowForwardIcon color="brand.500" />
                {endDate?.toDateString() ?? 'Checkout Date'}
              </>
            )}
            <CalendarIcon
              color={'brand.500'}
              onClick={(e) => {
                e.stopPropagation();
                toggleCal(!showCal);
              }}
            />
          </Text>
        </Flex>
        <Flex position={'relative'} zIndex={4}>
          <Flex
            display={showCal ? 'flex' : 'none'}
            position="absolute"
            bgColor={'white'}
            left={0}
            ml={[3, 5]}
            direction="column"
            align={'center'}
            shadow="xl"
          >
            {canSelectFlexiDates && <FlexiRange setShowCal={setShowCal} />}
            <DateRangeSelector
              onChange={setDates}
              flexiDays={flexiDaysBefore}
              defaultRange={defaultRange}
              single={!twoDates}
            />
          </Flex>
        </Flex>
      </>
    </Flex>
  );
}

function FlexiRange({ setShowCal }) {
  const { setFlexiDays, flexiDaysAfter, outing } = useUIStore(
    (state) => ({
      setFlexiDays: state.setFlexiDays,
      flexiDaysAfter: state?.trackerInput?.flexiDaysAfter,
      outing: state.outing,
    }),
    shallow
  );
  const canSelectTwoFlexiDays =
    TYPE_ENABLED.flexiTwoDays.indexOf(outing?.entity_type) > -1;
  const twoDates = TYPE_ENABLED.twoDates.indexOf(outing?.entity_type) > -1;
  const [flexiVal, setFlexiVal] = useState([`${flexiDaysAfter}`]);
  function addFlexi(e, days) {
    if (e.target.checked) {
      // replace val
      setFlexiVal([`${days}`]);
      setFlexiDays(days);
    } else {
      // remove everything
      setFlexiVal([]);
      setFlexiDays(0);
    }
  }
  return (
    <HStack
      spacing={4}
      fontSize="sm"
      fontWeight={500}
      color="brand.700"
      mt={1}
      w="100%"
      justify={'space-between'}
      px={4}
    >
      <HStack color="orange.500">
        <Text>Flexible:</Text>
        <CheckboxGroup
          size={'sm'}
          colorScheme="orange"
          value={flexiVal}
          defaultValue={flexiVal}
        >
          <Checkbox value="1" onChange={(e) => addFlexi(e, 1)}>
            ±1 Day
          </Checkbox>
          {canSelectTwoFlexiDays && (
            <Checkbox value="2" onChange={(e) => addFlexi(e, 2)}>
              ±2 Days
            </Checkbox>
          )}
        </CheckboxGroup>
      </HStack>
      <Flex>
        <Button
          colorScheme={'brand'}
          size="xs"
          onClick={() => setShowCal(false)}
        >
          Choose
        </Button>
      </Flex>
    </HStack>
  );
}
