import { INITIAL_STATE, STATE_TITLE_MAP, NZ_STATE_TITLE_MAP } from '#constants';
import getSlug from 'speakingurl';

export function toTitleCase(str) {
  if (!str) {
    return '';
  }
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
}

export function checkState(state) {
  const keysArray = Object.keys(STATE_TITLE_MAP);
  keysArray.push(...Object.keys(NZ_STATE_TITLE_MAP));
  if (keysArray.indexOf(getSlug(state)) > -1) {
    return state;
  }
  const randomKey = keysArray[Math.floor(Math.random() * keysArray.length)];
  return STATE_TITLE_MAP[randomKey];
}

// Be very careful, use only for display
// This function returns a new date that's the exact same time as UTC
// The returned date will be different (like it would be +5:30 from NYC)
export function convertDateToUTC(date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
}

function isLowercase(str) {
  return str === str.toLowerCase();
}

export function capitalize(text) {
  //split the above string into an array of strings
  //whenever a blank space is encountered
  if (!text) return '';

  // If the test is a slug let's split it
  if (text.indexOf(' ') === -1 && isLowercase(text)) {
    text = text.split('-').join(' ');
  }

  const arr = text.split(' ');

  //loop through each element of the array and capitalize the first letter.

  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
  }

  //Join all the elements of the array back into a string
  //using a blankspace as a separator
  const str2 = arr.join(' ');
  return str2;
}

export function blogDate(stringDate) {
  let date = stringDate;
  if (typeof date === 'string' || date instanceof String) {
    date = new Date(stringDate);
  }
  const months = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ];
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return `${day < 10 ? '0' + day : day} ${months[monthIndex]}, ${year}`;
}

export function kebabToCamel(str) {
  return str.replace(/-([a-z])/g, function (match) {
    return match[1].toUpperCase();
  });
}

export function timeSince(dateString) {
  const date = new Date(dateString);
  const now = new Date();
  const milliseconds = now.getTime() - date.getTime();
  const seconds = Math.floor(milliseconds / 1000);
  let interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return `${interval} years ago`;
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return `${interval} months ago`;
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return `${interval} days ago`;
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return `${interval} hours ago`;
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return `${interval} minutes ago`;
  }
  return `${Math.floor(seconds)} seconds ago`;
}

export function getTimeDifferenceInHours(date) {
  const now = new Date();
  const diffInMilliseconds = now.getTime() - date.getTime();
  const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
  return diffInHours;
}

export function displayUTCDate(date) {
  var month = date.getUTCMonth() + 1;
  var day = date.getUTCDate();
  var year = date.getUTCFullYear();
  return month + '/' + day + '/' + year;
}
