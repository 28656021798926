import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const isElementLoaded = async (selector) => {
  while (document.querySelector(selector) === null) {
    await new Promise((resolve) => requestAnimationFrame(resolve));
  }
  return document.querySelector(selector);
};

function ScrollToAnchor() {
  const { pathname, hash } = useLocation();
  const lastHash = useRef('');

  // listen to location change using useEffect with location as dependency
  // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        lastHash.current = hash.slice(1); // safe hash for further use after navigation
        if (lastHash.current && document.getElementById(lastHash.current)) {
          document
            .getElementById(lastHash.current)
            ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          lastHash.current = '';
        }
      }, 400);
    }
    window.scrollTo(0, 0);
  }, [hash, pathname]);

  return null;
}

export default ScrollToAnchor;
