import 'react-phone-input-2/lib/style.css';

import { Link, Text, VStack, Checkbox } from '@chakra-ui/react';
import { useState, useEffect } from 'react';

import PhoneInput from 'react-phone-input-2';
import { Link as ReactLink } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { useUIStore } from '#store';
import { SUPPORTED_COUNTRIES } from '#constants';

/** Component which renders the page where we get the phone number */
export function PhoneNumber() {
  const { contactId, setContactIdInput, registerContact, setToast } =
    useUIStore(
      (state) => ({
        contactId: state.contactInput.contactId,
        setContactIdInput: state.setContactIdInput,
        registerContact: state.registerContact,
        setToast: state.setToast,
      }),
      shallow
    );
  const [enableSubmit, setEnableSubmit] = useState(false);
  const checkValidity = async (inputNumber) => {
    setContactIdInput(inputNumber);
    const { isValidPhoneNumber } = await import('libphonenumber-js');
    setEnableSubmit(isValidPhoneNumber(`+${inputNumber}`));
  };

  useEffect(() => {
    checkValidity(contactId);
  }, []);

  const handlePhoneSumbit = () => {
    if (!enableSubmit) {
      return;
    }
    registerContact();
  };

  return (
    <VStack spacing={6}>
      {/* <Suspense fallback={<Skeleton w="300px" h="2.5em" />}> */}
      <PhoneInput
        country={'us'}
        onlyCountries={Object.keys(SUPPORTED_COUNTRIES).map((s) =>
          s.toLowerCase()
        )}
        placeholder="Enter phone number"
        value={contactId}
        onChange={checkValidity}
        countryCodeEditable={false}
        buttonStyle={{
          paddingLeft: 5,
          paddingRight: 5,
        }}
        inputStyle={{
          paddingTop: 20,
          paddingBottom: 20,
          paddingLeft: 54,
          fontSize: '1.1em',
        }}
        containerStyle={{
          width: 'auto',
        }}
      />
      <Checkbox
        colorScheme="white"
        color={'white'}
        alignItems={'baseline'}
        isChecked
        onChange={() => {
          setToast({
            title: "Can't proceed without concent",
            description: 'We require your consent to send you messages',
            status: 'error',
            duration: 2000,
            isClosable: true,
            position: 'top',
          });
        }}
      >
        <Text fontSize={'0.9em'}>
          By providing your phone number, you agree to receive text messages
          from Reserve Nature. Message and data rates may apply.
        </Text>
      </Checkbox>
      {/* </Suspense> */}
      <Link
        as={ReactLink}
        color="black"
        bg="white"
        px={4}
        py={2}
        rounded="md"
        fontWeight={500}
        _hover={{ opacity: enableSubmit ? 0.8 : 0.7 }}
        opacity={enableSubmit ? 1 : 0.7}
        onClick={handlePhoneSumbit}
        to=""
      >
        Send OTP
      </Link>
    </VStack>
  );
}
