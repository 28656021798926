import './styles/globals.css';
import './App.css';

import { ChakraProvider, Flex } from '@chakra-ui/react';
import { ErrorBoundary, Provider } from '@rollbar/react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Suspense, lazy, useEffect, useRef } from 'react';

import Checkout from './pages/Checkout.jsx';
import Footer from './components/Footer.jsx';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Landing from './pages/Landing.jsx';
import Loading from './components/Loading.jsx';
import Navigation from './components/Nav/Navigation.jsx';
import ScrollToTop from './utils/ScrollToTop';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import Toast from './components/Toast.jsx';
import rollbar from '#services/rollbar';
import { shallow } from 'zustand/shallow';
import { theme } from '#services/chakra';
import { useUIStore } from '#store';
import ScrollToAnchor from './components/ScrollToAnchor.jsx';

const PermitMapping = lazy(() =>
  import('./components/Admin/PermitMapping.jsx')
);
const MoneyBack = lazy(() => import('./components/Offers/MoneyBack.jsx'));
const HowItWorks = lazy(() => import('./pages/HowItWorks.jsx'));
const Outing = lazy(() => import('./pages/Outing.jsx'));
const Pricing = lazy(() => import('./pages/Pricing.jsx'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy.jsx'));
const ReviewRec = lazy(() => import('./pages/ReviewRec.jsx'));
const State = lazy(() => import('./pages/State.jsx'));
const Admin = lazy(() => import('./pages/Admin.jsx'));
const AdminLogin = lazy(() => import('./pages/AdminLogin.jsx'));
const AdminOffer = lazy(() => import('./pages/AdminOffer.jsx'));
const AdminTrackers = lazy(() => import('./pages/AdminTrackers.jsx'));
const Affiliate = lazy(() => import('./pages/Affiliate.jsx'));
const Blog = lazy(() => import('./pages/Blog.jsx'));
const CookiesPolicy = lazy(() => import('./pages/CookiesPolicy.jsx'));
const Country = lazy(() => import('./pages/Country.jsx'));
const FAQ = lazy(() => import('./pages/FAQ.jsx'));
const AboutUs = lazy(() => import('./pages/AboutUs.jsx'));
const NotFound = lazy(() => import('./pages/NotFound.jsx'));
const TermsOfService = lazy(() => import('./pages/TermsOfService.jsx'));
const EditBlog = lazy(() => import('./pages/EditBlog.jsx'));
const Profile = lazy(() => import('./pages/Profile.jsx'));
const Blogs = lazy(() => import('./pages/Blogs.jsx'));
const AdminDash = lazy(() => import('./pages/AdminDash.jsx'));
const Tracker = lazy(() => import('./pages/Tracker.jsx'));
const Testimonial = lazy(() => import('./pages/Testimonials.jsx'));
const Support = lazy(() => import('./pages/Support.jsx'));

function App() {
  const tawkMessengerRef = useRef();
  const { setTawk, putEvent } = useUIStore(
    (state) => ({
      setTawk: state.setTawk,
      putEvent: state.putEvent,
    }),
    shallow
  );
  useEffect(() => {
    setTawk(tawkMessengerRef);
    window.addEventListener('beforeunload', (ev) => {
      putEvent({
        type: 'exit',
      });
    });
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        putEvent({
          type: 'switch-in',
        });
      } else {
        putEvent({
          type: 'switch-out',
        });
      }
    });
  }, []);

  return (
    <Provider instance={rollbar}>
      <ErrorBoundary>
        <ChakraProvider theme={theme}>
          <GoogleOAuthProvider clientId="261892431720-du7o47ko9oqmcuq10e2s6l305q5aa0lf.apps.googleusercontent.com">
            <TawkMessengerReact
              propertyId="63314d9737898912e96b4499"
              widgetId="1gds9e5sg"
              ref={tawkMessengerRef}
            />
            <Toast />
            <Flex direction="column" minH="100vh" bgColor="gray.50">
              <Router>
                <ScrollToAnchor />
                <Navigation />
                <Suspense
                  fallback={
                    <Flex
                      w="100vw"
                      h="90vh"
                      align={'center'}
                      justify={'center'}
                    >
                      <Loading mode="control" loading />
                    </Flex>
                  }
                >
                  <Routes>
                    <Route path="/" element={<Landing />} index />
                    <Route path="/checkout" element={<Checkout />} />
                    <Route path="/profile/*" element={<Profile />} />
                    <Route path="/t/*" element={<Tracker />} />
                    <Route path="/offers/money-back" element={<MoneyBack />} />
                    <Route path="/campground/*" element={<Outing />} />
                    <Route path="/permit/*" element={<Outing />} />
                    <Route path="/recarea/*" element={<Outing />} />
                    <Route path="/state/*" element={<State />} />
                    <Route path="/testimonials" element={<Testimonial />} />
                    <Route path="/country/*" element={<Country />} />
                    <Route path="/blogs" element={<Blogs />} />
                    <Route path="/blogs/edit/*" element={<EditBlog />} />
                    <Route path="/blogs/*" element={<Blog />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/support" element={<Support />} />
                    <Route path="/how-it-works" element={<HowItWorks />} />
                    <Route path="/affiliate" element={<Affiliate />} />
                    <Route path="/admin/review-rec" element={<ReviewRec />} />
                    <Route path="/admin/admin-login" element={<AdminLogin />} />
                    <Route path="/admin/admin-offer" element={<AdminOffer />} />
                    <Route path="/admin/trackers" element={<AdminTrackers />} />
                    <Route
                      path="/admin/permit-mapping"
                      element={<PermitMapping />}
                    />
                    <Route path="/admin/dashboard" element={<AdminDash />} />
                    <Route path="/admin" element={<Admin />} />
                    <Route path="/cookies-policy" element={<CookiesPolicy />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route
                      path="/terms-of-service"
                      element={<TermsOfService />}
                    />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/*" element={<NotFound />} />
                  </Routes>
                </Suspense>
                <Footer />
              </Router>
            </Flex>
          </GoogleOAuthProvider>
        </ChakraProvider>
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
