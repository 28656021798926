import * as api from '../api.js';

import { CHECKOUT_STAGES, COUNTRY_CURRENCY_MAP } from '#constants';
import rollbar from '#services/rollbar';

export const reviewOrder = async (set, get) => {
  // Determine if we need to go to the payment stage or not
  const sub = get().sub;
  if (sub?._id) {
    // We have an active sub, create the tracker.
    await get().createTracker({ subId: sub._id });
    await get().setCheckoutStage(CHECKOUT_STAGES[2]);
    return;
  }
  // See if a discount code is applied
  if (get().code === 'YEAROFF') {
    return get().codeCheckout();
  }
  get().setCheckoutStage(CHECKOUT_STAGES[1]);
  return {};
};

export const codeCheckout = async (set, get) => {
  // We already have the subscription. Just create the tracker
  const code = get().code;
  const { success, failure } = await api.createCodeSub({
    code,
    planSlug: get().planSlug,
  });
  if (failure) {
    set({ loading: false });
    get().setToast({
      title: "Couldn't start subscription",
      description: failure.message,
      status: 'error',
      duration: 2000,
      isClosable: true,
      position: 'top',
    });
    // end loading
    return { failure: true };
  }

  const { subId } = success;
  // Step 1: Update the subscription to active
  await get().updateSubStatus({
    status: 'activate',
    _id: subId,
    paymentId: null,
    customPayment: {
      id: code,
      status: 'complete',
      amount: 0,
      currency: 'USD',
    },
  });

  get().setCheckoutStage(CHECKOUT_STAGES[2]);
  get().updateUser();
  return { success, failure };
};

export const createPaymentIntent = async (set, get) => {
  // Check if we actually need a intent
  if (get().sub?._id) {
    // We already have a subscription.
    // No need to get intent
    return { success: { data: 'Existing subscription' } };
  }
  if (get().code === 'YEAROFF') {
    // We shouldn't get intent
    // when we have a code.
    return { success: { data: 'Influencer signup' } };
  }
  let location = get().location;
  if (location.updated) {
    // We haven't fetched the current location wait for that to happen
    location = await get().getCurrentLocation();
  }
  const currency = COUNTRY_CURRENCY_MAP[location.country];
  const { success, failure } = await api.createPaymentIntent({
    planSlug: get().planSlug,
    currency,
  });
  if (failure) {
    return { failure: true };
  }
  get().setStripePayments({
    clientSecret: success.clientSecret,
    subId: success.subId,
    paymentId: success.paymentId,
  });
  return { success, failure };
};

export const removePaymentIntent = async (set, get) => {
  get().setStripePayments({
    clientSecret: null,
    subId: null,
    paymentId: null,
  });
  return { success: true };
};

export const onPaymentSuccess = async (set, get, { subId, paymentId }) => {
  // This is the place to setup the tracker.

  // Step 1: Update the subscription to active. Can't create a tracker on inactive sub
  await get().updateSubStatus({
    status: 'activate',
    _id: subId,
    paymentId,
  });
  // Step 2: Fetch the new subscriptions
  await get().getSub();
  // Step 3: Create the tracker
  await get().createTracker({ subId });
  // Step 4: Remove extra params from the URL
  get().setCheckoutStage(CHECKOUT_STAGES[2]);
};

// // This function tokenizes a payment method.
// // The ‘error’ thrown from this async function denotes a failed tokenization,
// // which is due to buyer error (such as an expired card). It is up to the
// // developer to handle the error and provide the buyer the chance to fix
// // their mistakes.
// export const tokenize = async (set, get, paymentMode) => {
//   const tokenResult = await paymentMode.tokenize();
//   if (tokenResult.status === 'OK') {
//     const verificationToken = await verifyBuyer(set, get, {
//       token: tokenResult.token,
//     });
//     return { token: tokenResult.token, verificationToken };
//   } else {
//     let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
//     if (tokenResult.errors) {
//       errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
//     }
//     get().setCardError(errorMessage);
//     rollbar.warning('Card Tokenization failed', {
//       tracker: get().trackerInput,
//     });
//     return {};
//   }
// };

// export const freeTracker = async (set, get) => {
//   // We already have the subscription. Just create the tracker
//   const { success, failure } = await api.createCodeSub({
//     code: 'WINTER-FREE',
//     planSlug: 'pay-as-you-go',
//   });
//   if (failure) {
//     set({ loading: false });
//     get().setToast({
//       title: "Couldn't create tracker",
//       description: failure.message,
//       status: 'error',
//       duration: 2000,
//       isClosable: true,
//       position: 'top',
//     });
//     // end loading
//     return { failure: true };
//   }
//   const { sub } = success;
//   get().createTracker({ subId: sub._id });
//   get().setCheckoutStage(CHECKOUT_STAGES[2]);
//   get().updateUser();
//   return { success, failure };
// };

// export const initializePayment = async (set, get) => {
//   if (get().sub?._id) {
//     // We already have the subscription. Just create the tracker
//     const { success, failure } = await get().createTracker({
//       subId: get().sub._id,
//     });
//     if (success) {
//       get().setCheckoutStage(CHECKOUT_STAGES[2]);
//     }
//     return { success, failure };
//   }
//   const paymentMode = get().paymentMode;
//   const { token, verificationToken } = get().checkout;

//   const data = {
//     token,
//     planSlug: get().planSlug,
//     address: get().address,
//     locationId: process.env.REACT_APP_PUBLIC_LOCATION_ID,
//     verificationToken,
//     paymentMode,
//   };

//   let apiToUse = api.createPayment;

//   // Step 2: Make the API call
//   // Check if it's a subscribe or pay once
//   if (get().planSlug !== 'pay-as-you-go') {
//     apiToUse = api.subscribe;
//   }
//   const { success, failure } = await apiToUse(data);

//   if (failure) {
//     set({ loading: false });
//     get().setToast({
//       title: 'Payment Failed',
//       description: failure.message,
//       status: 'error',
//       duration: 2000,
//       isClosable: true,
//       position: 'top',
//     });
//     // end loading
//     return { failure: true };
//   }
//   const { sub } = success;

//   // Step 3: Create the tracker
//   get().setCheckoutStage(CHECKOUT_STAGES[2]);
//   return get().createTracker({ subId: sub._id });
// };
