import {
  BASIC_FEATURES,
  PAY_AS_YOU_GO_FEATURES,
  PLAN_PROPERTIES_MAP,
} from '#constants';
import { Box, Flex, Icon, chakra } from '@chakra-ui/react';

import { CloseIcon } from '@chakra-ui/icons';

export default function Feature({ feat }) {
  return (
    <Flex align="center">
      <Flex shrink={0}>
        {feat.present ? (
          <Icon
            boxSize={5}
            mt={1}
            mr={[0, 2]}
            color="brand.500"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </Icon>
        ) : (
          <Icon
            as={CloseIcon}
            boxSize={3}
            w="28px"
            // trying to align the icons
            marginLeft={'-4px'}
            marginRight={['-4px', '4px']}
          ></Icon>
        )}
      </Flex>
      <Box ml={4}>
        <chakra.span mt={2} color="gray.700" fontSize={['sm', 'md']}>
          {feat?.text}
        </chakra.span>
      </Box>
    </Flex>
  );
}
