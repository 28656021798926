import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  Grid,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import { useEffect, useState } from 'react';

import { TYPES_MAP } from '#constants';
import { shallow } from 'zustand/shallow';
import { useUIStore } from '#store';

function Campsite({ campsite, selected, onClick }) {
  return (
    <Flex
      onClick={() => onClick(campsite)}
      bgColor={selected ? 'brand.500' : 'white'}
      borderColor={selected ? 'brand.500' : 'brand.500'}
      borderWidth={2}
      minH={12}
      maxH={20}
      alignItems="center"
      justify={'center'}
      borderRadius={3}
      opacity={campsite.reservable ? 1 : 0.5}
      _hover={{
        cursor: 'pointer',
        opacity: campsite.reservable ? 0.9 : 0.5,
      }}
      boxShadow="md"
      fontSize={'0.85em'}
      p={4}
    >
      <Tooltip
        label="Can't be reserved"
        isDisabled={campsite.reservable}
        hasArrow
      >
        <Text
          textAlign="center"
          color={selected ? 'white' : 'brand.900'}
          noOfLines={3}
          overflow="hidden"
          fontWeight={500}
        >
          {campsite.name}
        </Text>
      </Tooltip>
    </Flex>
  );
}

function CampsiteSelector() {
  const {
    campsites,
    addCampsitesToFilter,
    removeCampsitesFromFilter,
    trackerInput: { filters },
    setToast,
    outing,
  } = useUIStore(
    (state) => ({
      campsites: state.campsites,
      outing: state.outing,
      addCampsitesToFilter: state.addCampsitesToFilter,
      removeCampsitesFromFilter: state.removeCampsitesFromFilter,
      trackerInput: state.trackerInput,
      setToast: state.setToast,
    }),
    shallow
  );
  const selectedCampsites = filters.campsites;
  const onClick = (campsite) => {
    if (!campsite?.reservable) {
      setToast({
        title: `${TYPES_MAP[outing?.entity_type]} can't be reserved`,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: 'top',
      });
      return;
    }
    const campIndex = selectedCampsites?.indexOf(campsite._id);
    if (campIndex > -1) {
      // Campsite was selected, de-select it
      removeCampsitesFromFilter(campsite._id);
    } else {
      addCampsitesToFilter(campsite._id);
    }
  };
  if (!campsites?.length) {
    return (
      <Alert status="warning" variant="subtle" justifyContent={'center'}>
        <AlertIcon />
        There aren't any individual campsites available
      </Alert>
    );
  }
  return (
    <SimpleGrid
      overflowX="scroll"
      maxH="40vh"
      minH="150px"
      columns={[3, 4, 5]}
      spacingX={2}
      spacingY={[1, 2]}
    >
      {campsites.map((c, idx) => (
        <Campsite
          campsite={c}
          key={idx}
          onClick={onClick}
          selected={selectedCampsites?.indexOf(c._id) > -1}
        />
      ))}
    </SimpleGrid>
  );
}

export default function Campsites() {
  const {
    campsitesModal,
    toggleCampsitesModal,
    getCampsites,
    outing,
    resetCampsitesFilter,
    trackerInput,
  } = useUIStore(
    (state) => ({
      campsitesModal: state.campsitesModal,
      toggleCampsitesModal: state.toggleCampsitesModal,
      getCampsites: state.getCampsites,
      outing: state.outing,
      resetCampsitesFilter: state.resetCampsitesFilter,
      trackerInput: state.trackerInput,
    }),
    shallow
  );

  useEffect(() => {
    if (campsitesModal || trackerInput?.filters?.campsites) {
      // Only get the campsite when the modal opens
      // Or we already had campsites selected.
      getCampsites();
    }
  }, [outing?._id, campsitesModal]);

  return (
    <Modal
      isOpen={campsitesModal}
      onClose={() => toggleCampsitesModal(false)}
      size="3xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Choose one or more {TYPES_MAP[outing?.entity_type]}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody minH={200}>
          <CampsiteSelector />
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="red"
            variant="ghost"
            mr={3}
            onClick={() => {
              resetCampsitesFilter();
              toggleCampsitesModal(false);
            }}
          >
            Remove filter
          </Button>
          <Button
            colorScheme="brand"
            mr={3}
            onClick={() => toggleCampsitesModal(false)}
          >
            Save Selection
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
