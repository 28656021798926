import * as api from '../api.js';

import { INITIAL_STATE, STATE_TITLE_MAP } from '#constants';

export const getCurrentLocation = async (set, get) => {
  const { state_prov, city, country_code2 } = await api.getCurrentLocation();
  let physicalCountry = 'US';
  if (['NZ', 'AU'].indexOf(country_code2) > -1) {
    physicalCountry = 'NZ';
  } else if (['CA'].indexOf(country_code2) > -1) {
    physicalCountry = 'CA';
  }

  const location = {
    state: state_prov,
    city,
    country: physicalCountry,
    updated: true,
  };
  set({
    location,
  });
  return location;
};

export const getStateRecs = async (set, get, { stateCode }) => {
  const { success, failure } = await api.getStateRecs({
    stateCode,
  });
  if (failure) {
    return;
  }
  const { stateRecs } = success;
  // updating the user
  set({ stateRecs });
};

export const resetStore = async (set, get) => {
  set(
    {
      planSlug: '',
      trackerInput: INITIAL_STATE.trackerInput,
      searchInput: '',
      outing: {},
      sessionInfo: INITIAL_STATE.sessionInfo,
      user: {},
    },
    false,
    { type: 'resetStore' }
  );
};
