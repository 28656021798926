import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  HStack,
  Heading,
  Icon,
  Image,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { DISCOUNT_CODE } from '#constants';

import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { useUIStore } from '#store';

const OrderSuccess = () => {
  const [visible, setVisible] = useState(true);
  const navigate = useNavigate();
  const { code, setCode } = useUIStore(
    (state) => ({
      code: state.code,
      setCode: state.setCode,
    }),
    shallow
  );

  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
    }, 1700);
    return () => {
      // Let's remove the code from state
      setCode(null);
    };
  }, []);

  let message = 'Tracker Created!';
  if (code === 'YEAROFF') {
    message = DISCOUNT_CODE[code].successText;
  }

  return (
    <Flex justify={'center'} pt={[0, 4]}>
      <Card p={6} w={['90%', '60%']}>
        <CardBody>
          <Flex
            direction="column"
            align={'center'}
            gap={8}
            position={'relative'}
          >
            <Image
              position={'absolute'}
              src={'/public/order-success-2.gif'}
              boxSize={40}
              visibility={visible ? 'visible' : 'hidden'}
            />
            <Image
              src={'/public/order-success-image.png'}
              boxSize={40}
              visibility={!visible ? 'visible' : 'hidden'}
            />
            <Heading as="h1" size="lg">
              {message}
            </Heading>
            <Stack spacing={4} direction={['column', 'row']}>
              <Link
                onClick={() => navigate('/profile/trackers', { replace: true })}
              >
                <Button colorScheme={'brand'} variant={'outline'} minW={250}>
                  See all your trackers here
                </Button>
              </Link>
              <Link onClick={() => navigate('/', { replace: true })}>
                <Button colorScheme={'brand'} minW={250}>
                  Create more trackers
                </Button>
              </Link>
            </Stack>
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
};

export default OrderSuccess;
