import { Heading, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

export function BrandHeading({
  children,
  fontSize = { base: '3xl', sm: '4xl', lg: '6xl' },
  ...props
}) {
  return (
    <Heading
      lineHeight={1.1}
      fontWeight={600}
      fontSize={fontSize}
      mb={7}
      {...props}
      as="h1"
    >
      <Text
        as={'span'}
        position={'relative'}
        zIndex={1}
        _after={{
          content: "''",
          width: 'full',
          height: '0.25em',
          position: 'absolute',
          bottom: 1,
          left: 0,
          bg: 'brand.300',
          zIndex: -1,
        }}
      >
        {children}
      </Text>
    </Heading>
  );
}

export function BrandHeading2({
  children,
  fontSize = { base: '3xl', sm: '4xl', lg: '6xl' },
  changeOffset = 0,
  changeStyle,
  ...props
}) {
  const [text, colorText] = splitStringAtOffset(children, changeOffset);
  return (
    <Heading
      lineHeight={1.1}
      fontWeight={600}
      fontSize={fontSize}
      mb={7}
      {...props}
      as="h1"
    >
      <Text
        as={'span'}
        position={'relative'}
        zIndex={1}
        _after={{
          content: "''",
          width: '100%',
          height: '0.25em',
          position: 'absolute',
          bottom: 1,
          left: 0,
          bg: 'brand.300',
          zIndex: -1,
        }}
      >
        {text}
      </Text>
      <Text color="brand.400" {...changeStyle}>
        {colorText}
      </Text>
    </Heading>
  );
}

function splitStringAtOffset(str, wordOffsetFromRight) {
  // Split the string into an array of words
  const words = str.split(' ');

  // Calculate the index of the word at the specified offset from the right
  const index = words.length - wordOffsetFromRight;

  // Join the first part of the array up to the specified index
  const firstPart = words.slice(0, index).join(' ');

  // Join the second part of the array from the specified index
  const secondPart = words.slice(index).join(' ');

  // Return a tuple of the two parts
  return [firstPart, secondPart];
}
