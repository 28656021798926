import { Box, Button, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

import PaymentAction from '../PaymentAction.jsx';
import PlanSummary from './PlanSummary.jsx';
import TrackerSummary from './TrackerSummary.jsx';
import { shallow } from 'zustand/shallow';
import { useUIStore } from '#store';
import { DISCOUNT_CODE } from '#constants';

export default function ReviewOrder() {
  const { reviewOrder, sub, code } = useUIStore(
    (store) => ({
      reviewOrder: store.reviewOrder,
      sub: store.sub,
      code: store.code,
    }),
    shallow
  );

  let buttonText = 'Proceed to Payment';
  if (sub?._id) {
    buttonText = 'Create Tracker';
  }
  if (code === 'YEAROFF') {
    buttonText = DISCOUNT_CODE[code].buttonText;
  }

  return (
    <Flex
      align="stretch"
      direction={['column', 'row']}
      justify={'center'}
      mx={[0, '10%']}
      mb={10}
    >
      <Flex
        direction="column"
        w={['unset', '80%']}
        mx={[4, 6]}
        my={[4, 6]}
        p={[4, 8]}
        pb={[4, 12]}
        bgColor="white"
        borderRadius={5}
        boxShadow="md"
        flexShrink={1.5}
      >
        <PlanSummary />
        <Text fontSize={'sm'} textAlign={'center'} fontStyle={'italic'} mt={2}>
          Create with confidence! All trackers are editable within 24 hours of
          creation
        </Text>
      </Flex>
      <Flex
        direction="column"
        align={'stretch'}
        justify="space-between"
        mx={[4, 6]}
        my={[4, 6]}
        p={[4, 8]}
        pb={[4, 12]}
        w={['unset', '100%']}
        bgColor="white"
        borderRadius={5}
        boxShadow="md"
        flexShrink={2}
      >
        <TrackerSummary />
        <Button
          mb={[4, 12]}
          mt={4}
          colorScheme={'brand'}
          w="min-content"
          alignSelf={'center'}
          onClick={reviewOrder}
        >
          {buttonText}
        </Button>
      </Flex>
    </Flex>
  );
}
