import 'react-phone-input-2/lib/style.css';

import {
  Card,
  Heading,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react';

import { shallow } from 'zustand/shallow';
import { useUIStore } from '#store';
import GLogin from '../GLogin.jsx';
import { useEffect } from 'react';

export function SignUp() {
  const { putEvent } = useUIStore(
    (state) => ({
      putEvent: state.putEvent,
    }),
    shallow
  );
  useEffect(() => {
    putEvent({
      type: 'entry',
      data: {
        stage: 'signup-login',
      },
    });
  }, []);
  return (
    <SimpleGrid
      align={'stretch'}
      borderWidth={1}
      borderColor="brand.400"
      templateColumns={['1fr', '1fr 1fr']}
      minH="60vh"
      bg="green.400"
      pb={8}
    >
      <Card
        m={8}
        mb={[4, 8]}
        p={6}
        rounded="2xl"
        textAlign="center"
        align={'center'}
      >
        <Image
          src="/public/panda.png"
          fit="contain"
          rounded={'lg'}
          boxSize={[40, 350]}
        ></Image>
        <Text
          fontSize={['lg', '2xl']}
          mt={4}
          fontWeight={600}
          color={'gray.700'}
        >
          Login now, because the pandas are already in line for your campsite!
        </Text>
      </Card>
      <VStack align={'center'} justify="space-evenly" px={10} spacing={[8, 0]}>
        <VStack color="white" spacing={[2, 4]}>
          <Heading fontSize={['3xl', '5xl']}>
            Never worry about campsites again
          </Heading>
          <Text fontSize={['lg', 'xl']} fontWeight={500}>
            Create an account and let us take care of getting the best campsites
            for you.
          </Text>
        </VStack>
        <GLogin />
      </VStack>
    </SimpleGrid>
  );
}
