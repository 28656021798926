import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Icon,
  Image,
  Spacer,
  Stack,
  Text,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
} from '@chakra-ui/react';
import { useEffect, useRef, useState, useMemo } from 'react';
import AddressForm from './AddressForm.jsx';
import OrderSummary from './OrderSummary.jsx';

import { shallow } from 'zustand/shallow';
import { useUIStore } from '#store';
import CheckoutForm from './CheckoutForm.jsx';

import { loadStripe } from '@stripe/stripe-js';
import { useStripe, Elements } from '@stripe/react-stripe-js';

function SkeletonLoading() {
  return (
    <Flex
      align="stretch"
      direction={['column', 'row']}
      justify={'center'}
      mx={[0, '10%']}
    >
      <Flex
        direction="column"
        w={['unset', '80%']}
        mx={[4, 6]}
        my={[4, 6]}
        p={[4, 8]}
        pb={[4, 12]}
        bgColor="white"
        borderRadius={5}
        boxShadow="md"
        flexShrink={1}
      >
        <Heading as="h3" mb={4} fontWeight={500} fontSize={['2xl', '3xl']}>
          Billing Details:
        </Heading>
        <Skeleton height="40px" my={4} />
        <Skeleton height="40px" my={4} />
        <Skeleton height="40px" my={4} />
      </Flex>
      <Flex
        direction="column"
        align={'stretch'}
        justify="space-between"
        mx={[4, 6]}
        my={[4, 6]}
        p={[4, 8]}
        w={['unset', '100%']}
        bgColor="white"
        borderRadius={5}
        boxShadow="md"
        flexShrink={2}
        gap={6}
      >
        <OrderSummary />
        <Heading fontWeight={500} fontSize={['2xl', '3xl']} mb={4}>
          Card Details:
        </Heading>
        <Skeleton height="40px" />
        <HStack>
          <Skeleton height="40px" w="50%" />
          <Skeleton height="40px" w="50%" />
        </HStack>
        <Skeleton height="40px" w="50%" alignSelf={'center'} />
      </Flex>
    </Flex>
  );
}

export default function Payment() {
  const { stripePayments } = useUIStore(
    (store) => ({
      stripePayments: store.stripePayments,
    }),
    shallow
  );
  const stripePromise = useMemo(
    () => loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY),
    []
  );
  const clientSecret = stripePayments.clientSecret;
  const options = {
    clientSecret,
  };

  return clientSecret ? (
    <Elements options={options} stripe={stripePromise}>
      <Flex
        align="stretch"
        direction={['column', 'row']}
        justify={'center'}
        mx={[0, '10%']}
      >
        <Flex
          direction="column"
          w={['unset', '80%']}
          mx={[4, 6]}
          my={[4, 6]}
          p={[4, 8]}
          pb={[4, 12]}
          bgColor="white"
          borderRadius={5}
          boxShadow="md"
          flexShrink={1}
        >
          <AddressForm />
        </Flex>
        <Flex
          direction="column"
          align={'stretch'}
          justify="space-between"
          mx={[4, 6]}
          my={[4, 6]}
          p={[4, 8]}
          w={['unset', '100%']}
          bgColor="white"
          borderRadius={5}
          boxShadow="md"
          flexShrink={2}
          gap={6}
        >
          <OrderSummary />
          <CheckoutForm />
          <Spacer />
        </Flex>
      </Flex>
    </Elements>
  ) : (
    <SkeletonLoading />
  );
}
