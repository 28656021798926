import { v4 as uuidv4 } from 'uuid';

export const TYPES_MAP = {
  campground: 'Campsite',
  permit: 'Permit',
  timedentry_tour: 'Time',
  hotel: 'Room',
};

export const INITIAL_STATE = {
  trackerInput: {
    startDate: null,
    endDate: null,
    equipmentType: 'Tent',
    groupSize: 1,
    notificationPref: ['text', 'call'],
    flexiDaysBefore: 0,
    flexiDaysAfter: 0,
    filters: {
      campsites: [],
      accessible: false,
    },
    splitStay: false,
  },
  sessionInfo: {
    id: uuidv4(),
    createdAt: new Date(),
  },
  user: {},
  location: {
    state: 'Utah',
    city: '',
    country: 'US',
    updated: false,
  },
  testimonials: [],
};

export const NAV_LINKS = [
  { title: 'Where We Serve', link: '/country' },
  { title: 'Blogs', link: '/blogs' },
  { title: 'How It Works', link: '/how-it-works' },
  { title: 'About Us', link: '/about-us' },
  { title: 'Pricing', link: '/pricing' },
  { title: 'FAQs', link: '/faq' },
  { title: 'Testimonials', link: '/testimonials' },
];

export const TYPE_ENABLED = {
  groupSize: ['hotel', 'campground', 'permit', 'tour'],
  equipmentType: ['campground'],
  accessible: ['campground', 'hotel'],
  specific: ['hotel', 'campground', 'timedentry_tour', 'permit'],
  twoDates: ['campground', 'hotel'],
  flexiDates: ['campground', 'hotel', 'permit', 'timedentry_tour', 'tour'],
  flexiTwoDays: ['campground', 'hotel'],
  splitStay: ['campground'],
};

export const EQUIPMENT_TYPES = {
  'parks-canada': [
    'Small Tent',
    'Medium Tent',
    'Large Tent',
    'Van/Pickup',
    'Tent Trailer',
    'Trailer or Motorhome up to 21ft',
    'Trailer or Motorhome up to 24ft',
    'Trailer or Motorhome up to 27ft',
    'Trailer or Motorhome up to 35ft',
    'Trailer or Motorhome over 35ft',
  ],
  default: ['Tent', 'RV'],
};

// export const EQUIPMENT_TYPES = [
//   // 'Boat',
//   // 'Car',
//   // 'Caravan/Camper Van',
//   // 'Fifth Wheel',
//   // 'Horse',
//   // 'Large Tent Over 9X12`',
//   // 'Pickup Camper',
//   // 'Pop up',
//   'RV',
//   // 'RV/Motorhome',
//   // 'Small Tent',
//   'Tent',
//   // 'Trailer',
//   // 'Vehicle',
// ];
export const LOGO_URL_DARK = '/public/reserve-dark.png';
export const LOGO_URL_LIGHT = '/public/reserve-light.png';
export const COUNTRIES = ['US', 'CA', 'NZ', 'AU'];

export const CHECKOUT_STAGES = ['review-order', 'payment', 'success'];

export const PAY_AS_YOU_GO_FEATURES = [
  {
    text: 'Allows to create 1 Tracker per use',
    present: true,
  },
  {
    text: 'Get Call & Text as soon as there is availability',
    present: true,
  },
  {
    text: 'Checks for availability every 10 mins',
    present: true,
  },
  {
    text: 'Can book specific campsite/tour time',
    present: true,
  },
  {
    text: 'Allows flexible number of people in group',
    present: false,
  },
  {
    text: 'Allows to choose type of equipment for camping',
    present: false,
  },
  {
    text: 'Allows flexible dates of arrival',
    present: false,
  },
];

export const BASIC_FEATURES = [
  {
    text: 'Allows to create 5 Trackers per month',
    present: true,
  },
  {
    text: 'Get Call & Text as soon as there is availability',
    present: true,
  },
  {
    text: 'Checks for availability every 5 mins',
    present: true,
  },
  {
    text: 'Can book specific campsite/tour time',
    present: true,
  },
  {
    text: 'Allows flexible number of people in group',
    present: true,
  },
  {
    text: 'Allows to choose type of equipment for camping',
    present: true,
  },
  {
    text: 'Allows flexible dates of arrival',
    present: true,
  },
];

export const COUNTRY_CURRENCY_MAP = {
  US: 'USD',
  NZ: 'NZD',
  CN: 'CNY',
  JP: 'JPY',
  DE: 'EUR',
  GB: 'GBP',
  IN: 'INR',
  FR: 'EUR',
  IT: 'EUR',
  BR: 'BRL',
  CA: 'CAD',
  KR: 'KRW',
  RU: 'RUB',
  AU: 'AUD',
  ES: 'EUR',
  MX: 'MXN',
  ID: 'IDR',
  NL: 'EUR',
  TR: 'TRY',
  SA: 'SAR',
  CH: 'CHF',
};

export const PLAN_PROPERTIES_MAP = {
  'pay-as-you-go': {
    features: PAY_AS_YOU_GO_FEATURES,
    bgColor: 'dimgray',
    freq: '',
    priority: 'secondary',
    buttonText: 'Select to pay per use',
    upgradeText: 'Upgrade to Monthly Subscription',
    explainText: 'and create 5 trackers per month.',
  },
  'basic-monthly': {
    features: BASIC_FEATURES,
    bgColor: 'green',
    freq: 'month',
    priority: 'primary',
    buttonText: 'Subscribe',
    upgradeText: 'Upgrade to Annual Subscription',
    explainText: 'and save 55%',
  },
  'adventurer-monthly-pass': {
    features: BASIC_FEATURES,
    bgColor: 'green',
    freq: 'month',
    priority: 'primary',
    buttonText: 'Subscribe',
    upgradeText: 'Upgrade to Annual Subscription',
    explainText: 'and save 55%',
  },
  'nature-lovers-yearly-subscription': {
    features: BASIC_FEATURES,
    bgColor: 'green',
    freq: 'year',
    priority: 'primary',
    buttonText: 'Subscribe and Save',
    upgradeText: 'Change Plan',
    explainText: '',
  },
};

export const FONT_FAMILIES = [
  'Roboto',
  'Open Sans',
  'Lato',
  'Montserrat',
  'Source Sans Pro',
  'Raleway',
  'Poppins',
  'Ubuntu',
  'Playfair Display',
  'Merriweather',
  'Impact',
];

export const FONT_FAMILIES_SMALL = [
  'roboto',
  'open',
  'lato',
  'montserrat',
  'source',
  'raleway',
  'poppins',
  'ubuntu',
  'playfair',
  'merriweather',
  'impact',
];

export const SUB_STATUS = {
  INACTIVE: 'inactive', // Just created, doesn't get activated until a payment is made
  ACTIVE: 'active',
  PAUSED: 'paused',
  CANCELED: 'canceled',
  PAUSE_REQUESTED: 'pause-requested',
  RESUME_REQUESTED: 'resume-requested',
  CANCEL_REQUESTED: 'cancel-requested',
};

export const TWILIO_FROM = '+1(844)236-6494';

export const STATE_TITLE_MAP = {
  alabama: 'Alabama',
  alaska: 'Alaska',
  arizona: 'Arizona',
  arkansas: 'Arkansas',
  california: 'California',
  colorado: 'Colorado',
  connecticut: 'Connecticut',
  delaware: 'Delaware',
  florida: 'Florida',
  georgia: 'Georgia',
  hawaii: 'Hawaii',
  idaho: 'Idaho',
  illinois: 'Illinois',
  indiana: 'Indiana',
  iowa: 'Iowa',
  kansas: 'Kansas',
  kentucky: 'Kentucky',
  louisiana: 'Louisiana',
  maine: 'Maine',
  maryland: 'Maryland',
  massachusetts: 'Massachusetts',
  michigan: 'Michigan',
  minnesota: 'Minnesota',
  mississippi: 'Mississippi',
  missouri: 'Missouri',
  montana: 'Montana',
  nebraska: 'Nebraska',
  nevada: 'Nevada',
  'new-hampshire': 'New Hampshire',
  'new-jersey': 'New Jersey',
  'new-mexico': 'New Mexico',
  'new-york': 'New York',
  'north-carolina': 'North Carolina',
  'north-dakota': 'North Dakota',
  ohio: 'Ohio',
  oklahoma: 'Oklahoma',
  oregon: 'Oregon',
  pennsylvania: 'Pennsylvania',
  'rhode-island': 'Rhode Island',
  'south-carolina': 'South Carolina',
  'south-dakota': 'South Dakota',
  tennessee: 'Tennessee',
  texas: 'Texas',
  utah: 'Utah',
  vermont: 'Vermont',
  virginia: 'Virginia',
  washington: 'Washington',
  'west-virginia': 'West Virginia',
  wisconsin: 'Wisconsin',
  wyoming: 'Wyoming',
};

export const CA_STATE_TITLE_MAP = {
  alberta: 'Alberta',
  'british-columbia': 'British Columbia',
  manitoba: 'Manitoba',
  'new-brunswick': 'New Brunswick',
  'newfoundland-and-labrador': 'Newfoundland and Labrador',
  'northwest-territories': 'Northwest Territories',
  // nova-scotia: 'Nova Scotia',
  nunavut: 'Nunavut',
  ontario: 'Ontario',
  'prince-edward-island': 'Prince Edward Island',
  quebec: 'Quebec',
  saskatchewan: 'Saskatchewan',
  yukon: 'Yukon',
};

export const NZ_STATE_TITLE_MAP = {
  // northland: 'Northland',
  // auckland: 'Auckland',
  waikato: 'Waikato',
  // 'bay-of-plenty': 'Bay of Plenty',
  // gisborne: 'Gisborne',
  'hawkes-bay': "Hawke's Bay",
  // taranaki: 'Taranaki',
  // wellington: 'Wellington',
  // tasman: 'Tasman',
  nelson: 'Nelson',
  // marlborough: 'Marlborough',
  'west-coast': 'West Coast',
  // canterbury: 'Canterbury',
  otago: 'Otago',
  southland: 'Southland',
  'manawatu-whanganui': 'Manawatū-Whanganui',
};

export const attributes = [
  '<a href="https://www.freepik.com/free-vector/summer-camp-with-bonfire-tent-forest_16646714.htm?query=camping&collectionId=874&&position=2&from_view=collections">Image by upklyak</a> on Freepik',
  '<a href="https://www.freepik.com/free-vector/active-tourist-hiking-mountain-man-wearing-backpack-enjoying-trekking-looking-snowcapped-peaks-vector-illustration-nature-wilderness-adventure-travel-concept_10606146.htm#query=hiking&position=1&from_view=search&track=sph">Image by pch.vector</a> on Freepik',
  '<a href="https://www.freepik.com/free-vector/starry-sky-concept-illustration_37457767.htm#page=2&query=nature&position=7&from_view=search&track=sph">Image by storyset</a> on Freepik',
  'Image by <a href="https://www.freepik.com/free-vector/mountains-landscape-blue-gradient_5418638.htm#page=2&query=nature&position=49&from_view=search&track=sph">Freepik</a>',
  '<a href="https://www.freepik.com/free-vector/glamping-concept-illustration_26233025.htm#page=2&query=camping&position=2&from_view=search&track=sph">Image by storyset</a> on Freepik',
  '<a href="https://www.freepik.com/free-vector/cute-polar-bear-listening-music-cartoon-vector-icon-illustration-animal-technology-icon-concept-isolated-premium-vector-flat-cartoon-style_16306370.htm#query=bear%20call&position=1&from_view=search&track=ais">Image by catalyststuff</a> on Freepik',
];

export const SUPPORTED_COUNTRIES = {
  US: {
    titleMap: STATE_TITLE_MAP,
    name: 'USA',
  },
  CA: {
    titleMap: CA_STATE_TITLE_MAP,
    name: 'Canada',
  },
  NZ: {
    titleMap: NZ_STATE_TITLE_MAP,
    name: 'New Zealand',
  },
};

export const DISCOUNT_CODE = {
  YEAROFF: {
    buttonText: 'Start 1 year free subscription',
    bannerText: '🎉 Claim Free 1 year Subscription! 🎉',
    reviewButtonText: `That's a gift from us `,
    paymentButtonText: 'Start 1 year free subscription',
    successText: 'One year subscription started!',
  },
};
