import { Button, Flex, Icon, Link, Stack, Text } from '@chakra-ui/react';

import { BiError } from 'react-icons/bi';
import SuggestSection from './SuggestSection.jsx';
import { shallow } from 'zustand/shallow';
import { useUIStore } from '#store';

export default function Suggest() {
  const { tawk, suggestions, showSuggestions, noSearchResults } = useUIStore(
    (state) => ({
      tawk: state.tawk,
      suggestions: state.suggestedResults,
      showSuggestions: state.showSuggestions,
      noSearchResults: state.noSearchResults,
    }),
    shallow
  );
  const showNoResults = noSearchResults;
  if (!showSuggestions) return null;
  return (
    <Flex w="100%" position="relative">
      <Flex
        w="100%"
        align="stretch"
        direction="column"
        background="white"
        zIndex={4}
        overflowY="auto"
        maxH="30vh"
        position="absolute"
        boxShadow="0 1px 3px 0 rgb(60 64 67 / 30%), 0 4px 8px 3px rgb(60 64 67 / 15%)"
        fontWeight={400}
        fontStretch="normal"
        fontSize="14px"
        lineHeight="20px"
        fontFamily="Roboto, Arial, sans-serif"
        letterSpacing="0.2px"
      >
        {suggestions &&
          suggestions.map((suggestion) => {
            return (
              <SuggestSection
                key={suggestion.entity_id}
                suggestion={suggestion}
              />
            );
          })}
        {showNoResults && (
          <Stack spacing={1} px={4} py={3} color="gray.800">
            <Flex align={'center'}>
              <Icon as={BiError} mr={2} boxSize={5} color="red.600"></Icon>
              <Text fontWeight={'semibold'}>No Results Found</Text>
            </Flex>
            <Flex>
              <Text fontSize={'sm'}>Try something else, or </Text>
              <Link
                textDecor={'underline'}
                fontStyle="italic"
                _hover={{
                  textDecor: 'underline',
                  filter: 'brightness(0.1)',
                }}
                onMouseDown={(e) => {
                  tawk.current.maximize();
                }}
                ml={1}
                to=""
              >
                ask us to add this.
              </Link>
            </Flex>
          </Stack>
        )}
      </Flex>
    </Flex>
  );
}
