import * as api from '../api.js';

export const chat = async ({ messages }, set, get) => {
  const { success, failure } = await api.chat({
    messages,
  });
  if (failure) {
  }
  return { success };
};
