import * as api from '../api.js';

export const getPlans = async (set, get) => {
  const country = get().location?.country ?? 'US';
  const { success, failure } = await api.getPlans({ country });
  if (failure) {
    return;
  }
  set({ plans: success.plans }, false, { type: 'getPlans' });
};
