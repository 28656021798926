import * as api from '../api.js';

export const getGiftCards = async (set, get) => {
  const { success, failure } = await api.getGiftCards();
  if (failure) {
    get().setToast({
      title: "Can't fetch giftcards",
      description: failure?.message,
      status: 'error',
      duration: 2000,
      isClosable: true,
      position: 'top',
    });
    set({ giftCards: [] }, false, { type: 'getGiftCards' });
    return;
  }
  const { giftCards } = success;
  set({ giftCards }, false, { type: 'getGiftCards' });
};
