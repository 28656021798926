import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  forwardRef,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useUIStore } from '#store';

export default function UserTab() {
  const user = useUIStore((state) => state.user);
  const logoutUser = useUIStore((state) => state.logoutUser);
  if (!user) {
    return null;
  }
  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        leftIcon={
          <Avatar
            size="sm"
            src={user.picture}
            variant={'ghost'}
            colorScheme="teal"
          />
        }
        bg="none"
        _hover={{
          bg: 'none',
          opacity: 0.9,
        }}
        _active={{
          bg: 'none',
        }}
      />
      <MenuList>
        <MenuGroup title="Profile">
          <MenuItem as={Link} to="/profile">
            Dashboard
          </MenuItem>
          <MenuItem as={Link} to="/profile/trackers">
            Trackers
          </MenuItem>
        </MenuGroup>
        <MenuDivider />
        <MenuGroup title="Discounts">
          <MenuItem as={Link} to="/offers/money-back">
            Offers
          </MenuItem>
        </MenuGroup>
        <MenuDivider />
        <MenuItem as={Link} to="/" onClick={logoutUser}>
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
