import * as api from '../api.js';

export const postFeedback = async (
  { description, name, tracker, user },
  set,
  get
) => {
  const { success, failure } = await api.postFeedback({
    description,
    name,
    tracker,
    user,
  });
  if (failure) {
  }
};

export const fetchTestimonials = async (set, get) => {
  const { success, failure } = await api.getFeedbacks();
  if (failure) {
    return;
  }
  set({ testimonials: success.testimonials });
};
