import {
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Link as ReactLink, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { BrandHeading2 } from 'src/components/BrandHeading.jsx';
import Cookies from 'universal-cookie';
import { Helmet } from 'react-helmet';
import Testimonial from 'src/components/Testimonial.jsx';
import TrackWidget from 'src/components/Tracker/TrackWidget.jsx';
import { shallow } from 'zustand/shallow';
import { useUIStore } from '#store';
import { DISCOUNT_CODE } from '#constants';
import MetaInfo from 'src/components/MetaInfo';

const cookies = new Cookies();

function MobileText() {
  return (
    <Stack display={['flex', 'none']} zIndex={2}>
      <BrandHeading2
        fontSize={['8vw', '5xl']}
        as="h1"
        changeOffset={0}
        changeStyle={{
          color: 'brand.400',
        }}
        mb={-2}
      >
        {/* Get reservations at sold-out campgrounds or permits */}
        Secure your spot at fully
      </BrandHeading2>
      <BrandHeading2
        fontSize={['8vw', '5xl']}
        as="h1"
        changeOffset={3}
        changeStyle={{
          color: 'brand.400',
        }}
      >
        {/* Get reservations at sold-out campgrounds or permits */}
        booked campsites or permit.
      </BrandHeading2>
      <Text fontSize={['1em', '2xl']}>
        We will track the cancellations for your dates and give you a call/text
        as soon as someone cancels. So you can be the first to book that spot.
      </Text>
    </Stack>
  );
}
function DesktopText() {
  return (
    <Stack display={['none', 'flex']} zIndex={2}>
      <BrandHeading2
        fontSize={['3xl', '5xl']}
        as="h1"
        changeOffset={3}
        changeStyle={{
          color: 'brand.400',
        }}
        mb={[7, 1, 1, 1, 1, 7]}
      >
        {/* Get reservations at sold-out campgrounds or permits */}
        Secure your spot at fully booked campsites or permit.
      </BrandHeading2>
      <Text fontSize={['lg', '2xl']}>
        We will track the cancellations for your dates and give you a call/text
        as soon as someone cancels. So you can be the first to book that spot.
      </Text>
    </Stack>
  );
}

export default function Landing() {
  const {
    getPlans,
    setCurrentPage,
    setCheckoutStage,
    setPlanSlug,
    setCode,
    code,
  } = useUIStore(
    (state) => ({
      setCurrentPage: state.setCurrentPage,
      getPlans: state.getPlans,
      setCheckoutStage: state.setCheckoutStage,
      setPlanSlug: state.setPlanSlug,
      setCode: state.setCode,
      code: state.code,
    }),
    shallow
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [isImageLoading, setImageLoading] = useState(true);

  const meta = {
    title: 'Reserve Nature - Track Campgrounds, Permits availability',
    summary:
      'Reserve Nature helps people secure campground, permits reservations by tracking availability once those are sold out',
    hero: 'https://reservenature.com/public/reserve-dark.png',
    url: 'https://reservenature.com',
    type: 'website',
  };

  useEffect(() => {
    // Set the affiliate cookie
    const affiliateCode = searchParams.get('aff');
    if (affiliateCode) {
      const sevenDaysAhead = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
      cookies.set('affiliate', affiliateCode, {
        path: '/',
        expires: sevenDaysAhead,
      });
      searchParams.delete('aff');
    }
    setSearchParams(searchParams);
    setCurrentPage({ page: 'home' });
    getPlans();

    const paramCode = searchParams.get('code');
    if (paramCode) {
      setCode(paramCode);
      // searchParams.delete('code');
      setPlanSlug('nature-lovers-yearly-subscription');
    } else if (code === 'WINTER-FREE') {
      setPlanSlug('pay-as-you-go');
    } else {
      setPlanSlug(null);
    }
  }, []);
  let message = null;
  if (code === 'YEAROFF') message = DISCOUNT_CODE[code].bannerText;
  return (
    <VStack align={'stretch'} position="relative">
      <MetaInfo meta={meta} />
      {message && (
        <Link
          as={ReactLink}
          to={'/checkout'}
          position="absolute"
          w="100%"
          zIndex={1}
        >
          <Button colorScheme={'secondary'} w="100%" borderRadius={0}>
            {message}
          </Button>
        </Link>
      )}
      <MetaInfo meta={meta} />
      <Flex align="stretch" direction="column" h="calc(100vh - 70px)">
        <Image
          position="absolute"
          src={'hero-7-5-comp.jpg'}
          fit="cover"
          h="calc(100vh - 70px)"
          w="100%"
          zIndex={0}
          filter={isImageLoading ? 'blur(6px)' : 'blur(0px)'}
          onLoad={() => setImageLoading(false)}
          fallbackSrc="hero-7-5-mini.jpg"
        ></Image>
        <Flex
          align="center"
          direction="column"
          h="80vh"
          pt={[2, 6]}
          gap={[6, 0]}
        >
          <Stack
            w={['90%', '90%', '90%', '65%', '60%', '50%']}
            // my={[4, '4vh']}
            my={[4, 12, 12, 12, 12, 16]}
            textAlign="center"
            color={'black'}
            maxH="30vh"
          >
            <DesktopText />
            <MobileText />
          </Stack>
          <TrackWidget />
        </Flex>
        <Flex align="stretch" justify="center" direction="row"></Flex>
      </Flex>
      <Testimonial />
    </VStack>
  );
}
