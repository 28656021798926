import {
  Avatar,
  Box,
  Container,
  Flex,
  Heading,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import { BrandHeading } from './BrandHeading.jsx';
import { ReactNode } from 'react';

const Testimonial = ({ children }: { children: ReactNode }) => {
  return <Box>{children}</Box>;
};

const TestimonialContent = ({ children }: { children: ReactNode }) => {
  return (
    <Stack
      bg={'white'}
      color="gray.800"
      boxShadow={'lg'}
      p={8}
      rounded={'xl'}
      align={'center'}
      pos={'relative'}
      _after={{
        content: `""`,
        w: 0,
        h: 0,
        borderLeft: 'solid transparent',
        borderLeftWidth: 16,
        borderRight: 'solid transparent',
        borderRightWidth: 16,
        borderTop: 'solid',
        borderTopWidth: 16,
        borderTopColor: 'white',
        pos: 'absolute',
        bottom: '-16px',
        left: '50%',
        transform: 'translateX(-50%)',
      }}
    >
      {children}
    </Stack>
  );
};

const TestimonialHeading = ({ children }: { children: ReactNode }) => {
  return (
    <Heading as={'h3'} fontSize={'xl'}>
      {children}
    </Heading>
  );
};

const TestimonialText = ({ children }: { children: ReactNode }) => {
  return (
    <Text
      textAlign={'center'}
      color={useColorModeValue('gray.600', 'gray.400')}
      fontSize={'sm'}
    >
      {children}
    </Text>
  );
};

const TestimonialAvatar = ({
  src,
  name,
  title,
}: {
  src: string,
  name: string,
  title: string,
}) => {
  return (
    <Flex align={'center'} mt={8} direction={'column'}>
      <Avatar src={src} alt={name} mb={2} />
      <Stack spacing={-1} align={'center'}>
        <Text fontWeight={600}>{name}</Text>
        <Text fontSize={'sm'} color={useColorModeValue('gray.600', 'gray.400')}>
          {title}
        </Text>
      </Stack>
    </Flex>
  );
};

export default function WithSpeechBubbles() {
  return (
    <Box bg={useColorModeValue('gray.100', 'gray.700')}>
      <Container maxW={'7xl'} py={[12, 16]} as={Stack} spacing={12}>
        <Stack spacing={0} align={'center'}>
          <BrandHeading fontSize={{ base: '4xl', sm: '4xl', lg: '5xl' }} mb={2}>
            What Our Clients Speak
          </BrandHeading>
          <Text fontSize={['md', 'lg']}>
            They have been to the best of places!
          </Text>
        </Stack>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 10, md: 4, lg: 10 }}
        >
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Got Enchantments Permit!</TestimonialHeading>
              <TestimonialText>
                After what felt like decades of applying for the lottery and
                losing, was finally able to get the permit thanks to
                Reservenature.
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={'/public/yuliya-elsa.jpg'}
              name={'Yuliya Elsa'}
              title={'Yoga Teacher, Outdoor Enthusiast'}
            />
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Camping in Yosemite</TestimonialHeading>
              <TestimonialText>
                Never though we could get a campsite in the park, that too on a
                weekend! We got a notification within a day with the
                availability.
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={'/public/tracey-lenny.jpg'}
              name={'Tracey Lenny'}
              title={'Software Engineer'}
            />
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Havasupai off the list</TestimonialHeading>
              <TestimonialText>
                We had to cancel our reservation and because of reservanture we
                were able to get another one for our whole group.
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={'/public/brock-paislee.jpg'}
              name={'Brock Paislee'}
              title={'Hiker, Surfer'}
            />
          </Testimonial>
        </Stack>
      </Container>
    </Box>
  );
}
