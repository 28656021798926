import {
  Alert,
  AlertDescription,
  AlertIcon,
  Avatar,
  Box,
  Button,
  CloseButton,
  Divider,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import { FaGift, FaMoneyBillAlt } from 'react-icons/fa';
import {
  FiArrowRight,
  FiGift,
  FiHome,
  FiMenu,
  FiSettings,
  FiStar,
  FiTrendingUp,
} from 'react-icons/fi';

import { GiCash } from 'react-icons/gi';
import { MdEventRepeat } from 'react-icons/md';
import { NAV_LINKS } from '#constants';
import NavLink from '../Nav/NavLink.jsx';
import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { RiScanLine } from 'react-icons/ri';
import { shallow } from 'zustand/shallow';
import { useEffect } from 'react';
import { useUIStore } from '#store';

const Separator = () => (
  <Divider borderColor="brand.200" borderBottomWidth={1} w="unset" my={0.5} />
);
const MobileSiteMenu = ({ onClose }) => {
  const { user } = useUIStore(
    (state) => ({
      user: state.user,
    }),
    shallow
  );
  return (
    <Flex direction={'column'} as={'nav'} bg="white" w="100%" my={0}>
      {NAV_LINKS.map((link) => (
        <>
          <NavLink key={link.title} link={link.link} onClose={onClose}>
            {link.title}
          </NavLink>
          <Separator />
        </>
      ))}
      <NavLink
        key={'Admin Dashboard'}
        link={'/admin/dashboard'}
        display={user?.role === 'admin' ? 'block' : 'none'}
        bg="brand.500"
        color="white"
        _hover={{
          bg: 'brand.400',
        }}
        onClose={onClose}
      >
        Admin Dashboard
      </NavLink>
    </Flex>
  );
};

const ProfileNavLink = ({ icon, tab, children, onClose, to, ...rest }) => {
  const setProfileTab = useUIStore((state) => state.setProfileTab);
  return (
    <ReactLink
      _focus={{ boxShadow: 'none' }}
      onClick={() => {
        setTimeout(onClose, 200);
        setProfileTab(tab);
      }}
      to={to}
    >
      <Flex
        align="center"
        py={2}
        px={4}
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        {...rest}
      >
        {icon && <Icon mr="4" fontSize="16" as={icon} />}
        {children}
      </Flex>
    </ReactLink>
  );
};

const MobileProfileMenu = ({ onClose, ...rest }) => {
  const LinkItems = [
    {
      name: 'Dashboard',
      icon: FiHome,
      tab: 'dashboard',
      to: 'profile/dashboard',
    },
    {
      name: 'Trackers',
      icon: FiTrendingUp,
      tab: 'trackers',
      to: 'profile/trackers',
    },
    { name: 'Scans', icon: RiScanLine, tab: 'scans', to: 'profile/scans' },
    {
      name: 'Subscriptions',
      icon: MdEventRepeat,
      tab: 'subscriptions',
      to: 'profile/subscriptions',
    },
    {
      name: 'Earn Money',
      icon: FaMoneyBillAlt,
      tab: 'affiliate',
      to: 'profile/affiliate',
    },
    {
      name: 'Gift Cards',
      icon: FiGift,
      tab: 'gift-cards',
      to: 'profile/gift-cards',
    },
  ];
  const user = useUIStore((state) => state.user);
  return (
    <Box bg={'white'} borderRight="1px" borderRightColor={'gray.200'} {...rest}>
      <Stack spacing={0} w={['100%', '100%', 48, 52, 56, 64]}>
        <NavLink key={'my-profile'} link={'/profile'} onClose={onClose}>
          My Profile
        </NavLink>
        <Separator />
        {user?._id &&
          LinkItems.map((link) => (
            <ProfileNavLink
              key={link.name}
              onClose={onClose}
              to={link.to}
              {...link}
            >
              {link.name}
            </ProfileNavLink>
          ))}
      </Stack>
    </Box>
  );
};

export default function MobileNavBar({ isOpen, onOpen, onClose }) {
  return (
    <Drawer
      autoFocus={false}
      isOpen={isOpen}
      placement="left"
      onClose={onClose}
      returnFocusOnClose={false}
      onOverlayClick={onClose}
      size="xs"
    >
      <DrawerOverlay />
      <DrawerContent maxW="70%">
        <Stack>
          <Flex
            alignItems="center"
            mx={4}
            justifyContent="space-between"
            my={2}
          >
            <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
              Menu
            </Text>
            <CloseButton
              display={{ base: 'flex', md: 'none' }}
              onClick={onClose}
            />
          </Flex>
          <Separator />
          <MobileProfileMenu onClose={onClose} />
          <MobileSiteMenu onClose={onClose} />
        </Stack>
      </DrawerContent>
    </Drawer>
  );
}
