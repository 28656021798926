import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
  keyframes,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

function Step({
  isLast,
  data,
  index,
  currentIndex,
  title,
  onClick,
  direction,
}) {
  const stepBgColor = index < currentIndex ? 'brand.500' : 'blackAlpha.500';
  const stepFgColor = 'white';
  const forwardK = keyframes({
    '0%': {
      width: 0,
    },
    '100%': {
      width: '100%',
    },
  });
  const backwardK = keyframes({
    '0%': {
      width: '100%',
    },
    '100%': {
      width: 0,
    },
  });
  const forwardAni = `${forwardK} 0.75s ease-in-out`;
  const backwardAni = `${backwardK} 0.75s ease-in-out`;
  let animation = '';
  if (direction === 'forward' && index === currentIndex - 1) {
    animation = forwardAni;
  } else if (direction === 'backward' && index === currentIndex) {
    animation = backwardAni;
  }
  return (
    <Flex
      direction="row"
      alignItems={'center'}
      position="relative"
      justify={'center'}
      mb={4}
    >
      <Flex
        m={1}
        p={1}
        bg={stepBgColor}
        borderRadius={80}
        w={8}
        h={8}
        justify={'center'}
        color={stepFgColor}
        onClick={onClick}
        _hover={{
          cursor: 'pointer',
          opacity: 0.8,
        }}
      >
        {index === currentIndex && (
          <Flex
            borderColor={stepBgColor}
            borderRadius={80}
            w={10}
            h={10}
            top={0}
            justify={'center'}
            borderWidth={'thin'}
            position="absolute"
          />
        )}
        <Flex
          position={'absolute'}
          bottom={-6}
          color="black"
          w="max-content"
          fontSize={'sm'}
        >
          {title}
        </Flex>
        <Text fontSize={'sm'} mt="1px">
          {index + 1}
        </Text>
      </Flex>
      {!isLast && (
        <Flex h={1} bgColor="white" w={80}>
          <Box
            animation={animation}
            h="100%"
            bgColor="brand.500"
            w={index < currentIndex ? '100%' : '0%'}
          />
        </Flex>
      )}
    </Flex>
  );
}

export default function MultiStep({ steps, onClick, currentIndex }) {
  const selectedComponent = steps[currentIndex].component;
  const directionRef = useRef();
  return (
    <Flex direction="column" w="100%">
      <Flex
        direction="row"
        w="100%"
        justify={'center'}
        py={0}
        display={['none', 'flex']}
      >
        {steps.map((step, idx) => {
          return (
            <Step
              data={step}
              index={idx}
              isLast={idx === steps.length - 1}
              key={idx}
              currentIndex={currentIndex}
              title={step.title}
              onClick={() => {
                if (idx > currentIndex) {
                  directionRef.current = 'forward';
                } else {
                  directionRef.current = 'backward';
                }
                onClick(step);
              }}
              direction={directionRef.current}
            />
          );
        })}
      </Flex>
      <Box>{selectedComponent}</Box>
    </Flex>
  );
}
