import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  HStack,
  Icon,
  IconButton,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Tag,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  keyframes,
} from '@chakra-ui/react';
import { EQUIPMENT_TYPES, TYPES_MAP, TYPE_ENABLED } from '#constants';
import {
  EditIcon,
  InfoIcon,
  WarningIcon,
  QuestionOutlineIcon,
} from '@chakra-ui/icons';
import { MdArrowDropDown, MdPeople, MdCallSplit } from 'react-icons/md';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { CHECKOUT_STAGES } from '#constants';
import DateWidget from './DateWidget.jsx';
import { FaWheelchair } from 'react-icons/fa';
import Suggest from './Suggest.jsx';
import { debounce } from 'lodash-es';
import { shallow } from 'zustand/shallow';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { useUIStore } from '#store';

export default function TrackWidget() {
  const trackerInput = useUIStore((state) => state.trackerInput, shallow);
  const {
    groupSize,
    equipmentType,
    startDate,
    endDate,
    filters: { campsites, accessible },
    splitStay,
  } = trackerInput;
  const {
    searchInput,
    setSearchInput,
    getSuggestedResults,
    setShowSuggestions,
    setEquipmentType,
    setGroupSize,
    toggleContactModal,
    user,
    sub,
    setShowPricingModal,
    trackErrors,
    setTrackErrors,
    outing,
    setAccessible,
    setCheckoutStage,
    setSteps,
    setSplitStay,
    code,
    news,
  } = useUIStore(
    (state) => ({
      searchInput: state.searchInput,
      setSearchInput: state.setSearchInput,
      getSuggestedResults: state.getSuggestedResults,
      setShowSuggestions: state.setShowSuggestions,
      setEquipmentType: state.setEquipmentType,
      setGroupSize: state.setGroupSize,
      toggleContactModal: state.toggleContactModal,
      user: state.user,
      sub: state.sub,
      setShowPricingModal: state.setShowPricingModal,
      trackErrors: state.trackErrors,
      setTrackErrors: state.setTrackErrors,
      outing: state.outing,
      setAccessible: state.setAccessible,
      setCheckoutStage: state.setCheckoutStage,
      setSteps: state.setSteps,
      setSplitStay: state.setSplitStay,
      news: state.news,
      code: state.code,
    }),
    shallow
  );
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryName = searchParams.get('name');

  useEffect(() => {
    // Handle cases where the query params have data
    if (queryName) {
      setSearchInput(queryName);
      // if the entity is recarea, we need to search it
      if (outing?.entity_type === 'recarea') {
        getSuggestedResults(queryName);
      }
    }
  }, [queryName]);

  const getErrors = () => {
    const errors = {};
    if (!outing?._id) {
      return { searchInput: 'Please select a activity' };
    }
    if (outing.name.toLowerCase() !== searchInput.toLowerCase()) {
      return { searchInput: 'Please select a activity' };
    }
    if (outing.entity_type === 'campground') {
      // TODO: Check if equipmentType is supported for the outing
      errors.equipmentType = equipmentType
        ? null
        : 'Equipment type should be selected';
      if (!startDate || !endDate) {
        errors.date = 'Select a checkin date';
      } else if (startDate - endDate === 0) {
        errors.date = 'Checkout should be atleast a day after checkin';
      } else if (startDate < new Date()) {
        errors.date = 'Checkin date must be in the future';
      } else {
        errors.date = null;
      }
    } else {
      if (!startDate) {
        errors.date = 'Select a checkin date';
      } else if (!endDate) {
        errors.date = 'Select a checkout date';
      } else if (startDate < new Date()) {
        errors.date = 'Checkin date must be in the future';
      } else {
        errors.date = null;
      }
    }
    return errors;
  };

  const validate = () => {
    const errors = getErrors();
    setTrackErrors({ ...trackErrors, ...errors });
    if (Object.values(errors).find((e) => !!e)) {
      return false;
    } else {
      return true;
    }
  };

  const isUserVerified = () => {
    if (!user?.contacts?.[0]) return false;
    return user.contacts.some((c) => c.verified);
  };

  const handleClick = () => {
    const isValid = validate();
    if (!isValid) return;
    if (code === 'WINTER-FREE') {
      setSteps(['checkout']);
    } else {
      setSteps(['pricingModal', 'checkout']);
    }
    // Check is user has phone
    if (!isUserVerified()) {
      toggleContactModal(true);
    } else {
      if (sub?._id) {
        setShowPricingModal(false);
        navigate('/checkout');
        setCheckoutStage(CHECKOUT_STAGES[0]);
      } else {
        setShowPricingModal(true);
      }
    }
  };

  const canSelectGroup =
    TYPE_ENABLED.groupSize.indexOf(outing?.entity_type) > -1;
  const canSelectEquipment =
    TYPE_ENABLED.equipmentType.indexOf(outing?.entity_type) > -1;
  const canSelectAccessible =
    TYPE_ENABLED.accessible.indexOf(outing?.entity_type) > -1;
  const canSplitStay =
    TYPE_ENABLED.splitStay.indexOf(outing?.entity_type) > -1 &&
    outing?.crawlerType === 'recreation.gov';

  const debouncedGetSuggestedResults = useCallback(
    debounce(getSuggestedResults, 500),
    []
  );
  const error = Object.values(trackErrors).find((e) => !!e);
  const slideUp = keyframes`
    20%, 100% {
      transform: translate3d(0,-115%,-10px);
    }
  `;

  const availableEquipments =
    EQUIPMENT_TYPES[outing.crawlerType] ?? EQUIPMENT_TYPES.default;

  return (
    <Flex
      align="stretch"
      direction="column"
      boxShadow="dark-lg"
      borderRadius="8px"
      p={2}
      w={['95%', 'unset']}
      backgroundColor="white"
      // zIndex={2000000001} // display over tawk.to
      position="relative"
      sx={{ transformStyle: 'preserve-3d' }}
      zIndex={3}
    >
      {news?.show && (
        <Flex
          position={'absolute'}
          w="100%"
          transform="translateZ(-10px)"
          animation={`${slideUp} 2s linear 0s 1 forwards`}
          left={0}
        >
          <Text
            w="100%"
            py={[1]}
            bg="brand.500"
            borderTopRadius="lg"
            color="white"
            fontSize={['md', 'md']}
            fontWeight={500}
            textAlign={'center'}
            zIndex={2}
          >
            {news.text}
          </Text>
        </Flex>
      )}
      <Flex align={['center', 'stretch']} direction="column" w="100%">
        <Flex
          direction="row"
          justify={['center', 'space-between']}
          align="stretch"
          maxH="50%"
          w="100%"
        >
          <Flex ml={[0, 5]}>
            <Flex direction="row" m={2} justify="space-between" align="center">
              <Icon as={MdPeople} color="brand.500" boxSize={7} mr={1} />
              <NumberInput
                size="sm"
                maxW={16}
                defaultValue={1}
                min={1}
                onChange={setGroupSize}
                value={groupSize}
                max={50}
                isDisabled={!canSelectGroup}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Flex>
            <Flex p={2} maxW="30vh" align={'center'}>
              <FormControl isInvalid={trackErrors.equipmentType}>
                <Select
                  icon={<MdArrowDropDown />}
                  placeholder="Select Type"
                  size="sm"
                  value={equipmentType}
                  onChange={(e) => {
                    setEquipmentType(e.target.value);
                  }}
                  disabled={!canSelectEquipment}
                  width={['100%', 'max-content']}
                >
                  {availableEquipments.map((entity) => (
                    <option key={entity} value={entity}>
                      {entity}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                isInvalid={trackErrors.accessible}
                width={['auto', '100%']}
              >
                <Checkbox
                  isChecked={accessible}
                  onChange={(e) => setAccessible(e.target.checked)}
                  disabled={!canSelectAccessible}
                  size="lg"
                  ml={4}
                  w="max-content"
                  colorScheme={'brand'}
                >
                  <HStack spacing={1} fontSize={'md'}>
                    <Icon as={FaWheelchair} color="brand.500"></Icon>
                    <Text display={['none', 'block']}>Accessible</Text>
                  </HStack>
                </Checkbox>
              </FormControl>
              <FormControl
                isInvalid={trackErrors.splitStay}
                width={['auto', '100%']}
                position={'relative'}
              >
                <Checkbox
                  isChecked={splitStay}
                  onChange={(e) => setSplitStay(e.target.checked)}
                  disabled={!canSplitStay}
                  size="lg"
                  ml={2}
                  w="max-content"
                  colorScheme={'brand'}
                >
                  <HStack spacing={0} fontSize={'md'}>
                    <Icon as={MdCallSplit} color="brand.500" boxSize={6}></Icon>
                    <Text display={['none', 'block']}>Split Stay</Text>
                  </HStack>
                </Checkbox>
                <Popover>
                  <PopoverTrigger>
                    <Icon
                      as={QuestionOutlineIcon}
                      color="brand.500"
                      boxSize={[2, 3]}
                      right={[-2, -4]}
                      top={[-1, 0]}
                      position={'absolute'}
                      _hover={{
                        cursor: 'pointer',
                      }}
                    ></Icon>
                  </PopoverTrigger>
                  <PopoverContent
                    _focusVisible={{
                      outline: 0,
                    }}
                  >
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody mr={2}>
                      You can split your stay across campsites if required.{' '}
                      <Link
                        to={'/faq#what-is-the-split-stay-feature'}
                        style={{
                          color: 'blue',
                        }}
                      >
                        Read More
                      </Link>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </FormControl>
            </Flex>
          </Flex>
          <Flex
            justify={'center'}
            align="center"
            transition="visibility 0.15s ease-out"
            visibility={error ? 'visible' : 'hidden'}
            mr={5}
            display={['none', 'flex']}
          >
            <WarningIcon mr={2} color="red.600" />
            <Text color="red.500">{error}</Text>
          </Flex>
        </Flex>

        <Flex
          direction="row"
          justify="space-around"
          w={['100%', '75vw']}
          flexFlow={['wrap', 'nowrap']}
        >
          <Flex
            direction="column"
            align="stretch"
            w={['100%', '50%']}
            mx={3}
            my={2}
          >
            <FormControl isInvalid={trackErrors.searchInput}>
              <Input
                placeholder="Search Campgrounds, Permits or Anything"
                size="md"
                flexShrink={2}
                onChange={(e) => {
                  setSearchInput(e.target.value);
                  debouncedGetSuggestedResults(e.target.value);
                }}
                onFocus={(e) => {
                  e.target.select();
                  setShowSuggestions(true);
                }}
                onBlur={() => {
                  setShowSuggestions(false);
                }}
                value={searchInput}
                textColor="blackAlpha.900"
                fontFamily="Roboto, Arial, sans-serif"
                autoComplete="off"
              />
            </FormControl>
            <Suggest />
          </Flex>
          <DateWidget />
          <Button
            colorScheme="brand"
            size="md"
            width={['60%', '25%']}
            mx={3}
            my={2}
            flexShrink={8}
            onClick={handleClick}
            textColor="white"
            fontFamily="Roboto, Arial, sans-serif"
          >
            Track
          </Button>
        </Flex>
        <SelectedCampsites />
      </Flex>
      <ExtraInfo />
    </Flex>
  );
}

function ExtraInfo() {
  const { outing } = useUIStore(
    (state) => ({
      outing: state.outing,
    }),
    shallow
  );
  return (
    <Flex
      justify={'center'}
      align="center"
      transition="visibility 0.95s linear"
      mr={5}
      ml={[0, 0]}
      fontSize={'sm'}
      display={outing?.parent_name === 'Milford Track' ? 'flex' : 'none'}
      // visibility={
      //   outing?.parent_name === 'Milford Track' ? 'visible' : 'hidden'
      // }
      position={'absolute'}
      bottom={[-10, -5]}
      left={0}
      w="100%"
      bg="brand.200"
      py={0.5}
      pl={2}
      borderBottomRadius={'md'}
    >
      <InfoIcon mr={2} color="blue.600" />
      <Text color="blue.500">
        To track all huts for Milford Walk, choose entry date for Clinton Hut
      </Text>
    </Flex>
  );
}

function SelectedCampsites() {
  const { trackerInput, campsites, outing, toggleCampsitesModal } = useUIStore(
    (state) => ({
      trackerInput: state.trackerInput,
      campsites: state.campsites,
      outing: state.outing,
      toggleCampsitesModal: state.toggleCampsitesModal,
    }),
    shallow
  );
  const entityType = outing?.entity_type;
  const canSelectChild =
    TYPE_ENABLED.specific.indexOf(outing?.entity_type) > -1;
  const selectedCampsites = trackerInput?.filters?.campsites ?? [];
  const campsiteMap = useMemo(() => {
    return campsites?.reduce((prev, curr) => {
      prev[curr._id] = curr;
      return prev;
    }, {});
  }, [campsites]);
  const highlightAni = keyframes`
    0%, 100% {
      // box-shadow: none;
      background-color: transparent;
      color: green
    }
    40%, 60% {
      // box-shadow: 0 0 20px 1px green;
      background-color: #C6F6D5;
      // color: #68D391
    }
  `;
  const [animation, setAnimation] = useState('none');
  useEffect(() => {
    // Trying to highlight the button
    if (outing?._id) {
      setAnimation(`${highlightAni} 2s linear 0.7s infinite forwards`);
      setTimeout(() => setAnimation('none'), 4000);
    }
  }, [outing?._id]);
  if (!selectedCampsites?.length) {
    return (
      <Button
        animation={campsites?.length > 0 ? animation : 'unset'}
        size="sm"
        colorScheme={'brand'}
        variant="ghost"
        onClick={() => toggleCampsitesModal(true)}
        isDisabled={!canSelectChild}
        alignSelf={'flex-start'}
        ml={1}
        opacity={campsites?.length > 0 ? 1 : 0.6}
      >
        {canSelectChild ? `Select Specific ${TYPES_MAP[entityType]}` : ''}
      </Button>
    );
  }
  return (
    <Flex
      my={1}
      ml={3}
      flexWrap={['wrap', 'nowrap']}
      rowGap={2}
      maxW={['100%', '70vw']}
      alignSelf={'flex-start'}
      alignItems="center"
      maxH={40}
      overflowY="scroll"
      overflowX="scroll"
    >
      <Text fontSize={'sm'} w="max-content" fontWeight={500} color="brand.700">
        {TYPES_MAP[entityType]}:
      </Text>
      <Flex display={['none', 'flex']} flexWrap="wrap">
        {selectedCampsites.map((c) => (
          <Tag key={c} colorScheme="green" mx={1} my={1}>
            <Text maxW={28} noOfLines={[1, 2]} py={'2px'}>
              {campsiteMap[c]?.name}
            </Text>
          </Tag>
        ))}
      </Flex>
      <Flex display={['flex', 'none']} ml={2}>
        <Tag colorScheme="green">{selectedCampsites.length} chosen </Tag>
      </Flex>
      <IconButton
        size="sm"
        h="24px"
        colorScheme={'brand'}
        variant="ghost"
        icon={<EditIcon boxSize={5} />}
        onClick={() => toggleCampsitesModal(true)}
      ></IconButton>
    </Flex>
  );
}
