import {
  Card,
  Heading,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react';
import { shallow } from 'zustand/shallow';
import { useUIStore } from '#store';
import { PhoneNumber } from './PhoneNumber.jsx';
import { VerifyContact } from './VerifyContact.jsx';
import { useEffect } from 'react';

/** The parent of Phone Number and Verify Contact */
export function RegisterContact() {
  const { user, contactStatus, putEvent } = useUIStore(
    (state) => ({
      user: state.user,
      contactStatus: state.contactStatus,
      putEvent: state.putEvent,
    }),
    shallow
  );
  const isUserVerified = () => {
    if (!user?.contacts?.[0]) return false;
    return user.contacts.some((c) => c.verified);
  };

  const isUserRegistered = () => {
    return user?.contacts?.length > 0;
  };

  let shownComponent = <PhoneNumber />;
  if (isUserRegistered() && contactStatus === 'verify' && !isUserVerified()) {
    shownComponent = <VerifyContact />;
  }
  useEffect(() => {
    if (isUserRegistered() && contactStatus === 'verify' && !isUserVerified()) {
      // We are in the verify contact stage
      putEvent({
        type: 'entry',
        data: {
          stage: 'verify-phone',
        },
      });
    } else {
      putEvent({
        type: 'entry',
        data: {
          stage: 'add-phone',
        },
      });
    }
  }, [user]);

  return (
    <SimpleGrid
      align={'stretch'}
      h="100%"
      borderWidth={1}
      borderColor="brand.400"
      minH="60vh"
      bg="green.400"
      templateColumns={['1fr', '1fr 1fr']}
      pb={8}
    >
      <Card
        m={8}
        mb={[4, 8]}
        p={6}
        rounded="2xl"
        textAlign="center"
        align={'center'}
      >
        <Image
          src="/public/bear.png"
          fit="contain"
          rounded={'lg'}
          boxSize={[40, 350]}
        ></Image>
        <Text
          fontSize={['lg', '2xl']}
          mt={4}
          fontWeight={600}
          color={'gray.700'}
        >
          The bears respect your privacy. They only text about camping updates.
        </Text>
      </Card>
      <VStack align={'center'} justify="space-evenly" px={10}>
        <VStack color="white" spacing={4}>
          <Heading fontSize={['3xl', '5xl']}>
            Get instant call on availability
          </Heading>
          <Text fontSize={['lg', 'xl']} fontWeight={500}>
            Sharing your phone number with us helps us notify you on
            availability of campsite or permit.
          </Text>
        </VStack>
        {shownComponent}
      </VStack>
    </SimpleGrid>
  );
}
