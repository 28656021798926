import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';
import { ArrowForwardIcon, CalendarIcon } from '@chakra-ui/icons';
import {
  Button,
  Card,
  Checkbox,
  CheckboxGroup,
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  Select,
  SimpleGrid,
  StylesProvider,
  Text,
  useOutsideClick,
} from '@chakra-ui/react';
import { useCallback, useRef, useState } from 'react';

// import Select from 'react-select';
import Suggest from './Suggest';
import { debounce } from 'lodash-es';
import { shallow } from 'zustand/shallow';
import { useUIStore } from '#store';

function getDaysOfLastMonthInFirstWeek(month, year) {
  const firstDayOfMonth = new Date(year, month, 1).getDay();

  const lastMonth = month === 0 ? 11 : month - 1; // Handle January
  const lastYear = month === 0 ? year - 1 : year;
  const daysInLastMonth = new Date(lastYear, lastMonth + 1, 0).getDate();

  const days = [];

  for (let i = firstDayOfMonth - 1; i >= 0; i--) {
    days.push(new Date(lastYear, lastMonth, daysInLastMonth - i));
  }
  return days;
}

function getDaysInMonth(month, year) {
  var today = new Date(year, month, 1);
  var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const daysInMonth = lastDayOfMonth.getDate();
  const days = [];

  for (let i = 1; i <= daysInMonth; i++) {
    days.push(new Date(year, month, i));
  }

  return days;
}

const monthOptions = [
  { value: 0, label: 'Jan' },
  { value: 1, label: 'Feb' },
  { value: 2, label: 'Mar' },
  { value: 3, label: 'Apr' },
  { value: 4, label: 'May' },
  { value: 5, label: 'Jun' },
  { value: 6, label: 'Jul' },
  { value: 7, label: 'Aug' },
  { value: 8, label: 'Sep' },
  { value: 9, label: 'Oct' },
  { value: 10, label: 'Nov' },
  { value: 11, label: 'Dec' },
];

const yearOptions = [
  { value: 2023, label: '2023' },
  { value: 2024, label: '2024' },
  { value: 2025, label: '2025' },
  { value: 2026, label: '2026' },
];

const getDaysOfNextMonthInLastWeek = (month, year) => {
  var today = new Date(year, month, 1);
  var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const daysInMonth = lastDayOfMonth.getDay();
  const daysToFill = 6 - daysInMonth;
  const days = [];

  for (let i = 1; i <= daysToFill; i++) {
    days.push(new Date(year, month + 1, i));
  }

  return days;
};

const isToday = (date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

const areSame = (date1, date2) => {
  if (!date1 || !date2) {
    return false;
  }
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  return d1.getTime() === d2.getTime();
};

function oneSelected(range) {
  const d1 = range?.[0];
  const d2 = range?.[1];
  return areSame(d1, d2);
}

function inRange(date, range) {
  return (
    date >= (range?.[0] ?? new Date(3000, 0, 0)) &&
    date <= (range?.[1] ?? new Date(1970, 0, 0))
  );
}

function isLastDayOfMonth(date) {
  const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
  const lastDayOfMonth = new Date(nextMonth - 1);
  return date.getDate() === lastDayOfMonth.getDate();
}

function isPreviewWeekFirst(date, range) {
  return inRange(date, range) && (date.getDay() === 0 || date.getDate() === 1);
}

function isPreviewWeekLast(date, range) {
  return (
    inRange(date, range) && (date.getDay() === 6 || isLastDayOfMonth(date))
  );
}

function inDelta(date, pivotDate, flexiDays) {
  const newDate = new Date(pivotDate);
  newDate.setDate(newDate.getDate() + flexiDays);
  const datesArray = [pivotDate, newDate];
  datesArray.sort((a, b) => a - b);
  return inRange(date, datesArray);
}

function mergeStyles(s1, s2) {
  return {
    inner: {
      ...(s1.inner ?? {}),
      ...(s2.inner ?? {}),
      _hover: { ...s1.inner._hover, ...(s2?.inner?._hover ?? {}) },
    },
    outer: {
      ...(s1.outer ?? {}),
      ...(s2.outer ?? {}),
      _hover: { ...s1.outer._hover, ...(s2?.outer?._hover ?? {}) },
    },
  };
}

const renderWeekdays = () => {
  const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  return weekdays.map((weekday) => (
    <Text
      key={weekday}
      align="center"
      fontSize={'xs'}
      fontWeight={400}
      color="gray.500"
    >
      {weekday}
    </Text>
  ));
};

const UnitDate = ({
  date,
  disabled,
  selectedRange,
  handleClick = () => null,
  handleMouseEnter = () => null,
  previewRange = [],
  flexiDays = 0,
}) => {
  const isNow = isToday(date);
  const isStart = areSame(date, selectedRange?.[0]);
  const isEnd = areSame(date, selectedRange?.[1]);
  const isSelected = inRange(date, selectedRange);
  const isPreview = inRange(date, previewRange);
  const isPreviewStart =
    areSame(date, previewRange?.[0]) || isPreviewWeekFirst(date, previewRange);
  const isPreviewEnd =
    areSame(date, previewRange?.[1]) || isPreviewWeekLast(date, previewRange);
  const isFlexi =
    inDelta(date, selectedRange?.[1], flexiDays) ||
    inDelta(date, selectedRange?.[0], -flexiDays);

  let style = { inner: { _hover: {} }, outer: { _hover: {} } };
  const selectedStyle = {
    inner: {
      bg: 'brand.400',
      color: 'white',
      borderRadius: '3xl',
    },
    outer: {},
  };
  const startStyle = {
    outer: {},
    inner: { borderLeftRadius: '3xl' },
  };
  const endStyle = {
    outer: {},
    inner: { borderRightRadius: '3xl' },
  };
  const previewStyle = {
    outer: {
      borderColor: 'brand.500',
      borderRightColor: 'transparent',
      borderLeftColor: 'transparent',
    },
  };
  const previewStartStyle = {
    outer: {
      _hover: {},
      borderLeftColor: 'brand.500',
      borderLeftRadius: '2xl',
    },
  };
  const previewEndStyle = {
    outer: {
      borderRightColor: 'brand.500',
      borderRightRadius: '2xl',
    },
  };
  const flexiStyle = {
    inner: {
      bg: 'brand.100',
      borderRadius: '3xl',
    },
  };

  if (isFlexi) style = mergeStyles(style, flexiStyle);
  if (isSelected) style = mergeStyles(style, selectedStyle);
  if (isStart) style = mergeStyles(style, startStyle);
  if (isEnd) style = mergeStyles(style, endStyle);
  if (isPreview) style = mergeStyles(style, previewStyle);
  if (isPreviewStart) style = mergeStyles(style, previewStartStyle);
  if (isPreviewEnd) style = mergeStyles(style, previewEndStyle);
  return (
    <Flex
      p={'1px'}
      my={1}
      mx={'-1px'}
      align="center"
      color={disabled ? 'gray.400' : 'gray.600'}
      fontSize="xs"
      fontWeight={isNow ? 700 : 400}
      opacity={disabled ? 0.8 : 1}
      position="relative"
      borderWidth={1}
      borderColor={'transparent'}
      onClick={() => !disabled && handleClick(date)}
      onMouseEnter={() => !disabled && handleMouseEnter(date)}
      _hover={{
        cursor: 'default',
        borderColor: 'transparent',
        ...style.outer._hover,
      }}
      _after={{
        content: '""',
        position: 'absolute',
        borderBottomColor: 'brand.500',
        borderBottomWidth: 2,
        w: '40%',
        right: '50%',
        transform: 'translate(50%)',
        display: isNow ? 'block' : 'none',
        bottom: '4px',
      }}
      {...style.outer}
    >
      <Flex
        minW={10}
        textAlign="center"
        {...style.inner}
        minH="24px"
        align={'center'}
        justify={'center'}
        _hover={{
          cursor: 'default',
        }}
      >
        {date.getDate()}
      </Flex>
    </Flex>
  );
};

const Month = ({
  month,
  year,
  selectedRange,
  handleClick,
  handleMouseEnter,
  handleMouseLeave,
  previewRange,
  flexiDays,
}) => {
  const prevMonthDays = getDaysOfLastMonthInFirstWeek(month, year);
  const currentMonthdays = getDaysInMonth(month, year);
  const nextMonthDays = getDaysOfNextMonthInLastWeek(month, year);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return (
    <SimpleGrid columns={7} onMouseLeave={handleMouseLeave}>
      {renderWeekdays()}
      {prevMonthDays.map((d) => (
        <UnitDate
          date={d}
          disabled
          selectedRange={selectedRange}
          flexiDays={flexiDays}
          key={d.toString()}
        />
      ))}
      {currentMonthdays.map((d) => (
        <UnitDate
          date={d}
          disabled={d < today}
          selectedRange={selectedRange}
          handleClick={handleClick}
          handleMouseEnter={handleMouseEnter}
          previewRange={previewRange}
          flexiDays={flexiDays}
          key={d.toString()}
        />
      ))}
      {nextMonthDays.map((d) => (
        <UnitDate
          date={d}
          disabled
          selectedRange={selectedRange}
          flexiDays={flexiDays}
          key={d.toString()}
        />
      ))}
    </SimpleGrid>
  );
};

function Calendar({
  selectedRange,
  handleClick,
  handleMouseEnter,
  handleMouseLeave,
  previewRange,
  setMonth,
  setYear,
  month,
  year,
  decMonth,
  incMonth,
  flexiDays,
}) {
  return (
    <Flex p={2} minW={'318px'} justify="center">
      <Flex direction="column">
        <Flex w="100%" justify={'space-between'} mb={2} align="center">
          <IconButton size="xs" icon={<AiFillCaretLeft />} onClick={decMonth} />
          <Select
            size="xs"
            h={8}
            border="none"
            maxW={20}
            outline={'none'}
            _hover={{
              bg: 'gray.100',
              borderRadius: 'md',
            }}
            _active={{
              outline: 'none',
            }}
            _focusVisible={{
              outline: 'none',
            }}
            onChange={(e) => setMonth(Number(e.target.value))}
            value={month}
          >
            {monthOptions.map((o) => (
              <option value={o.value} key={o.value}>
                {o.label}
              </option>
            ))}
          </Select>
          <Select
            size="xs"
            border="none"
            maxW={20}
            h={8}
            outline={'none'}
            value={year}
            _hover={{
              bg: 'gray.100',
              borderRadius: 'md',
            }}
            _active={{
              outline: 'none',
            }}
            _focusVisible={{
              outline: 'none',
            }}
            onChange={(e) => setYear(Number(e.target.value))}
          >
            {yearOptions.map((o) => (
              <option value={o.value} key={o.value}>
                {o.label}
              </option>
            ))}
          </Select>
          <IconButton
            size="xs"
            icon={<AiFillCaretRight />}
            onClick={incMonth}
          />
        </Flex>
        <Month
          month={month}
          year={year}
          selectedRange={selectedRange}
          handleClick={handleClick}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          previewRange={previewRange}
          flexiDays={flexiDays}
        />
      </Flex>
    </Flex>
  );
}

export default function DateRangeSelector({
  onChange,
  flexiDays,
  defaultRange,
  single = false,
}) {
  const [selectedRange, setSelected] = useState(defaultRange);
  const [previewRange, setPreviewRange] = useState([]);
  // Use the defaultRange's month and year for default
  const defDate = defaultRange?.length ? new Date(defaultRange[0]) : new Date();
  const defMonth = defDate.getMonth();
  const defYear = defDate.getFullYear();
  const [month, setMonth] = useState(defMonth);
  const [year, setYear] = useState(defYear);

  function handleMouseEnter(date) {
    if (!single && oneSelected(selectedRange)) {
      const datesArray = [selectedRange[0], date];
      datesArray.sort((a, b) => a - b);
      setPreviewRange(datesArray);
    } else {
      setPreviewRange([date, date]);
    }
  }

  function handleMouseLeave() {
    setPreviewRange([]);
  }

  function handleClick(date) {
    if (!single && oneSelected(selectedRange)) {
      const datesArray = [selectedRange[0], date];
      datesArray.sort((a, b) => a - b);
      setSelected(datesArray);
      setPreviewRange([]);
      onChange(datesArray);
    } else {
      setSelected([date, date]);
      setPreviewRange([date, date]);
      onChange([date, date]);
    }
  }

  function decMonth() {
    if (month === 0) {
      // change year
      setYear(year - 1);
      setMonth(11);
      return;
    }
    setMonth(month - 1);
  }

  function incMonth() {
    if (month === 11) {
      // change year
      setYear(year + 1);
      setMonth(0);
      return;
    }
    setMonth(month + 1);
  }

  return (
    <Calendar
      selectedRange={selectedRange}
      handleClick={handleClick}
      handleMouseEnter={handleMouseEnter}
      handleMouseLeave={handleMouseLeave}
      previewRange={previewRange}
      setYear={setYear}
      setMonth={setMonth}
      month={month}
      year={year}
      incMonth={incMonth}
      decMonth={decMonth}
      flexiDays={flexiDays}
    />
  );
}
