import 'react-phone-input-2/lib/style.css';

import {
  Flex,
  HStack,
  IconButton,
  Link,
  PinInput,
  PinInputField,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { EditIcon, WarningIcon } from '@chakra-ui/icons';
import { useEffect, useState } from 'react';

import { shallow } from 'zustand/shallow';
import { useUIStore } from '#store';

export function VerifyContact() {
  const {
    contactId,
    contactModalError,
    setShowPricingModal,
    verifyContact,
    toggleContactModal,
    user,
    registerContact,
    currentPage,
    code,
    setContactStatus,
  } = useUIStore(
    (state) => ({
      contactId: state.contactInput.contactId,
      contactModalError: state.contactModalError,
      setShowPricingModal: state.setShowPricingModal,
      verifyContact: state.verifyContact,
      toggleContactModal: state.toggleContactModal,
      user: state.user,
      registerContact: state.registerContact,
      currentPage: state.currentPage,
      code: state.code,
      setContactStatus: state.setContactStatus,
    }),
    shallow
  );

  const [seconds, setSeconds] = useState(0);
  const [parsedPhoneNumber, setParsedPhoneNumber] = useState('');
  const [isLoading, setLoading] = useState(false);
  const minutes = Math.floor(seconds / 60);
  const secondsToDisplay = seconds - minutes * 60;

  const parseInputPhoneNumber = async () => {
    const { parsePhoneNumber } = await import('libphonenumber-js');
    const libParsedPhoneNumber = parsePhoneNumber(
      `+${contactId}`
    ).formatInternational();
    setParsedPhoneNumber(libParsedPhoneNumber);
  };
  const handleEdit = () => {
    // Set the status to register
    setContactStatus('register');
  };

  useEffect(() => {
    // For displaying the number
    parseInputPhoneNumber();
    resetTimer();
  }, [contactId]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const resetTimer = () => {
    setSeconds(30);
  };

  const handleComplete = async (value) => {
    setLoading(true);
    const { success } = await verifyContact({ otp: value });
    await new Promise((res) => setTimeout(res, 1000)); // Wait for 1 sec to show the loader
    setLoading(false);
    if (success) {
      toggleContactModal(false);
      if (currentPage !== 'profile' && code !== 'YEAROFF') {
        // Don't show pricing in case of affiliates
        setShowPricingModal(true);
      }
    }
  };

  return (
    <Flex direction="column" align={'center'} fontFamily="sans-serif">
      <Flex
        my={2}
        fontWeight={'bold'}
        fontSize="lg"
        color="gray.100"
        align={'center'}
        direction={'column'}
      >
        <Text>Enter OTP sent to {parsedPhoneNumber}</Text>
        <Text
          fontSize={'xs'}
          color="gray.200"
          textDecor={'underline'}
          fontStyle={'italic'}
          _hover={{
            cursor: 'pointer',
          }}
          onClick={handleEdit}
        >
          Doesn't look right? Edit number..
        </Text>
      </Flex>
      <HStack mt={4} position="relative">
        <PinInput
          otp
          onComplete={handleComplete}
          colorScheme="red"
          focusBorderColor="whiteAlpha.800"
        >
          <PinInputField color={'white'} />
          <PinInputField color={'white'} />
          <PinInputField color={'white'} />
          <PinInputField color={'white'} />
        </PinInput>
        <Spinner
          display={isLoading ? 'inline-block' : 'none'}
          position="absolute"
          right={-10}
          color="white"
        />
      </HStack>
      <Flex
        align={'center'}
        my={2}
        opacity={contactModalError ? 1 : 0}
        transition="opacity 0.3s linear"
      >
        <WarningIcon mr={2} color="red.600" />
        <Text>{contactModalError}</Text>
      </Flex>
      <Flex justify={'center'} mt={2} fontStyle="italic">
        {seconds > 0 ? (
          <Text color="gray.700">
            Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
            {secondsToDisplay < 10 ? `0${secondsToDisplay}` : secondsToDisplay}
          </Text>
        ) : (
          <Text color="gray.700">
            Didn&apos;t get the OTP?
            <Link
              ml={2}
              onClick={() => {
                // Resending OTP
                resetTimer();
                registerContact();
              }}
              color="whiteAlpha.800"
              textDecor={'underline'}
              to=""
            >
              Resend
            </Link>
          </Text>
        )}
      </Flex>
    </Flex>
  );
}
