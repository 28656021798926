import * as api from '../api';

import TokenService from '#services/token';
import rollbar from '#services/rollbar';

export const adminLogin = async (set, get, { email, id, password }) => {
  const { success, failure } = await api.adminLogin({
    id,
    email,
    password,
  });

  if (failure) {
    get().setToast({
      title: 'Login failed',
      description: failure.message,
      status: 'error',
      duration: 2000,
      isClosable: true,
      position: 'top',
    });
    return { failure };
  }

  const { token, user, planSlug } = success;
  get().setPlanSlug(planSlug);
  TokenService.setToken(token);
  get().updateUser({ user, calle: 'adminLogin' });
  return { success };
};

export const oAuthLogin = async (set, get, { credential }) => {
  const { success, failure } = await api.oAuthLogin({
    credential,
  });

  if (failure) {
    /* empty */
  }
  const { token, user, planSlug } = success;
  if (get().code !== 'YEAROFF') {
    // skip in case of discount
    get().setPlanSlug(planSlug);
  }
  TokenService.setToken(token);
  get().updateUser({ user, calle: 'oAuthLogin' });
  // Backfilling userId in the session
  await api.backfillEvents({
    sessionId: get().sessionInfo?.id,
  });
  // Logging the event
  get().putEvent({
    type: 'search',
    data: {
      query: get().searchInput,
      outingName: get().outing?.name,
    },
  });
};

const postUpdateUser = async (set, get) => {
  if (!get().user) {
    return;
  }
  // We should get these after user's login
  // 1. Get their subscripition
  await get().getSub();
};

/**
 * This is the function that is called when we want to load the user into state
 */
export const updateUser = async (set, get, { user, calle }) => {
  let apiUser = user;
  if (!user && !TokenService.getToken()) {
    return;
  }
  if (!user && TokenService.getToken()) {
    // get the user again but only if there is token
    const { success, failure } = await api.getUser();
    if (failure) {
      // The user is not logged in.
      return;
    }
    if (success?.user) {
      apiUser = success?.user;
    }
  }

  TokenService.setUser(apiUser);
  rollbar.configure({
    payload: {
      person: {
        id: apiUser?._id,
        email: apiUser?.email,
      },
    },
  });

  set({ user: apiUser }, false, { type: calle, user });
  await postUpdateUser(set, get);
};

export const logoutUser = async (set, get) => {
  TokenService.removeToken();
  TokenService.removeUser();
  rollbar.configure({
    payload: {
      person: {
        id: null,
      },
    },
  });
  get().resetStore();
  set({ user: null }, false, 'logoutUser');
};
